import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SellModel from "../Services/Model/SellModel";
import { customerDB, sellDB } from "../DB/sell";

export const initialState = {
    item_details: [],
    daily_sells: [],
    item_discount: [],
    current_invoice: null,
    hard_refresh: 0
}
export const getDailySellsThunk = createAsyncThunk('addSell/getDailySells', async (thunkApi) => {
    // const db = new SellModel();
    // const response = await db.getDailySellsRegular();
    let currentDate = new Date()
    const sellData = await sellDB.find({
        "selector": {
            "order_date": {
                "$eq": currentDate.toISOString().split('T')[0]
            }
        }
    })
    console.log("All docs from pouch")
    console.log(sellData)
    return sellData.docs;
});
export const sellSubmit = createAsyncThunk('addSell/sellSubmit', async (payload, thunkApi) => {
    console.log(payload);
    const db = new SellModel();
    const { customer_name, customer_id } = payload.billDetails;
    console.log(customer_id, customer_name);
    if (customer_id === 1 && customer_name !== "") {
        const customerId = await db.addCustomer(payload.billDetails);
        payload.billDetails.new_customer_id = customerId;
    }
    const response = await db.addSellRegular(payload);

    console.log("SellSubmit => ", response)

    return response;
})
export const sellSubmitPouchDB = createAsyncThunk('addSell/sellSubmitPouchDB', async (payload, thunkApi) => {
    payload._id = Date.now().toString()
    let usercode = localStorage.getItem("_usercode");
    payload.bill_details.usercode = usercode
    if (payload.bill_details.customer_id === 1 && payload.bill_details.customer_name === "") {
        let customerData = await customerDB.find({
            selector: {
                "id": 1
            }
        })
        if (customerData.docs.length > 0) {
            payload.bill_details.customerID = customerData.docs[0]._id
            payload.bill_details.customer_id = customerData.docs[0].id
            payload.bill_details.customer_name = customerData.docs[0].name
            payload.bill_details.customer_contact = customerData.docs[0].mobile
            payload.bill_details.customer_balance = parseInt(customerData.docs[0].balance)
            payload.bill_details.wallet_amt = parseInt(customerData.docs[0].wallet_amt)
        }

    }
    payload.invoice = payload.bill_details.invoice
    payload.order_date = payload.bill_details.order_date
    payload.bill_total = payload.bill_details.total
    payload.timestamp = payload._id

    payload.bill_details.invoice_date = new Date().toJSON().slice(0, 19).replace('T', ' ')
    console.log("Post payload couch =>", payload)
    await sellDB.post(payload)
    return payload;
})

export const AddSellReducer = createSlice({
    name: "addsell",
    initialState,
    reducers: {
        addItemToList: (state, action) => {
            var index = state.item_details.findIndex(d => (d.item_id ===
                action.payload.item_id && d.batchno === action.payload.batchno))
            console.log("index =>", index, action.payload.item_id)
            if (index === -1) {

                state.item_details.push(calculateTotal(action.payload));
            } else {
                console.log("Input Data ======", action.payload)
                // var newstate = state.item_details.filter((d) => d.itemId !== action.payload.itemId);
                // newstate.push(action.payload);

                let oldState = state.item_details[index];
                console.log("max qnty => ", action.payload.maxQnty)
                console.log("current qnty => ", oldState.qnty)

                if (oldState.qnty < action.payload.maxQnty) {
                    oldState.qnty = oldState.qnty + 1
                }
                state.item_details[index] = calculateTotal(oldState)



            }
        },
        editItemInList: (state, action) => {
            console.log(action.payload)
            var index = state.item_details.findIndex((d) => d.item_id === action.payload.item_id);
            console.log(index)
            let mergedEdit = { ...state.item_details[index], ...action.payload.data }
            console.log("Editing item in row state => ", mergedEdit)

            state.item_details[index] = calculateTotal(mergedEdit)
        },
        editRowItem: (state, action) => {

        },
        deleteItemFromList: (state, { payload }) => {
            console.log(payload.item_id)
            state.item_details = state.item_details.filter((d) => d.item_id !== payload.item_id);
        },
        // sellSubmit: (state, { payload }) => {

        // },
        addItemDiscount: (state, { payload }) => {
            payload.forEach(element => {
                var index = state.item_details.findIndex((d) => d.item_id === element.item_id);

                state.item_details[index].disc = element.discount
            });


        },
        resetItemDetails: (state, { payload }) => {
            state.item_details = [];
        },
        closePrintPopup: (state, { payload }) => {
            state.current_invoice = null;
        },
        openPrintPopup: (state, { payload }) => {
            state.current_invoice = payload.bill_details.invoice
            // state.hard_refresh = Math.random()
        }


    }, extraReducers: {
        [getDailySellsThunk.fulfilled]: (state, action) => {
            // console.log(action.payload)
            state.daily_sells = action.payload
        },
        [sellSubmit.fulfilled]: (state, action) => {
            state.item_details = [];
            // getDailySellsThunk()
            // console.log("From fulfilled reducers", action.payload);
            // state.hard_refresh = Math.random()
            // state.current_invoice = action.payload
        },
        [sellSubmitPouchDB.fulfilled]: (state, action) => {
            state.item_details = [];

        }
    }

})

export const { addItemToList, addItemDiscount, deleteItemFromList, openPrintPopup, editItemInList, editRowItem, resetItemDetails, getDailySells, closePrintPopup } = AddSellReducer.actions

export default AddSellReducer.reducer


const calculateTotal = (oldState) => {

    let mrpTotal = oldState.mrp * oldState.qnty;
    let discTotal = mrpTotal - (mrpTotal * oldState.disc / 100) - oldState.ru
    oldState.total = discTotal
    console.log("Calculating total => ", oldState)
    return oldState
}
import logo from '../assets/logo.png'
export default function HomePageLoading({loadingPer}) {
    return (
        <div className="relative container min-h-screen  w-72 m-auto ">
            <div className="absolute pt-1 top-1/3 w-full">
                <div className="h-40">
                    <img alt="Loading ..." className="" src={logo} />
                </div>
                <div className="flex mb-2 items-center justify-between">
                    <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                            Installation in progress
                    </span>
                    </div>
                    <div className="text-right">
                        <span className="text-xs font-semibold inline-block text-blue-600">
                            {loadingPer}%
                    </span>
                    </div>
                </div>
                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                    <div style={{ width: `${loadingPer}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
                </div>
            </div>
        </div>
    )
}
import React from 'react'
import { CancelSync, RemoteCustomerDatabase, RemoteSellDatabase, RemoteStockDatabase } from '../DB/sell';
const defaultState = {
    isAuthenticated: false,
    user: null,
    token: null,
};

export const reducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':

            let updatedState = {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                token: action.payload.token
            };
            localStorage.setItem("_userState", JSON.stringify(updatedState));
            // localStorage.setItem("token", JSON.stringify(action.payload.token));
            return updatedState;

        case "LOGOUT":
            localStorage.clear();
            CancelSync()
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };
        default:
            return state;
    }

}
const userState = localStorage.getItem("_userState");
export const initialState = userState != null ? JSON.parse(userState) : defaultState;
export const AuthContext = React.createContext(initialState);
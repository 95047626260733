import React, { useContext, Fragment, useRef, useEffect } from 'react'
import { AuthContext } from '../Context/AuthContext'
import logo from '../assets/main-logo.png';
// import { Link } from 'react-router-dom';
import autocomplete from 'autocompleter';
import axios from 'axios';
// import Notification from './Notification';
import { Menu, Transition } from '@headlessui/react';
import { useHistory } from 'react-router-dom';
import { syncInit } from '../Reducers/SyncReducer';
import { useDispatch } from 'react-redux';
import { getDailySellsThunk } from '../Reducers/AddSellReducer';
// import { listenSSE } from '../Services/SSE';
import SearchComponent from './Header/SearchComponent';
// import SSEConnectionStatus from './Header/SSEConnectionStatus';
// import { ChevronDownIcon } from "@heroicons/react/solid";
// import { useRef } from 'react/cjs/react.development';
// import { useEffect } from 'react/cjs/react.production.min';


// import Notification from './Notification'
export default function Header() {
    const { state, dispatch } = useContext(AuthContext)
    // const [notVisible, setNotVisible] = useState(false)
    // const [userMenuVisible, setUserMenuVisible] = useState(false)
    let history = useHistory();
    let searchField = useRef(null);
    // const [sseStatus, setSSEStatus] = useState(false)
    const dispatchAction = useDispatch();


    useEffect(() => {

        // const timer = setInterval(() => {

        //     dispatchAction(syncInit()).then(() => {
        //         console.log("Finished syncing")
        //         dispatchAction(getDailySellsThunk());
        //     });
        // }, 30000)
        // return () => {
        //     clearInterval(timer);
        // }
        // syncStart()
        // listenSSE((data) => {
        //     setSSEStatus(data)

        //     console.log(data)
        //     if (data) {
        //         syncStart()
        //     } else {
        //     }


        // });
    }, [])
    useEffect(() => {
        bindMasterSearchAutoComplete()
        // alert("sync")

    }, [])
    // const syncStart = () => {
    //     dispatchAction(syncInit()).then(() => {
    //         console.log("Finished syncing")
    //         dispatchAction(getDailySellsThunk());
    //     });
    // }
    const bindMasterSearchAutoComplete = () => {
        autocomplete({
            input: searchField.current,
            className: "w-full",
            fetch: function (text, update) {
                text = text.toLowerCase();
                axios.get(`/api/MasterSearch?q=` + text).then(d => {
                    if (d.status === 200) {
                        if (d.data !== null) {
                            var suggestions = [];
                            d.data.forEach(e => {
                                suggestions.push({ label: e.label, value: e.id, type: e.type })
                            })
                            update(suggestions);
                        }

                    }
                })


            },
            onSelect: function (item) {
                searchField.current.value = item.label;
                history.push(`/masterSearch/${item.type}/${item.value}`);
                console.log(item);
                // switch (item.type) {
                //     case 'customer':
                // }
                // alert(item.value)
                // callback(item, field)
                // callback(item.value);
            },
            customize: function (input, inputRect, container, maxHeight) {
                container.style.width = "auto";

            }
        });
    }
    return (
        <>
            <header className="w-full border-b border-gray-200  z-50 bg-white ">
                <nav className="p-2 z-50 ">

                    <div className=" flex flex-wrap">
                        <div className="flex-shrink ">

                            <a href="/" className="block text-center">
                                <div className=" text-2xl font-bold mx-2 text-blue-500">

                                    {/* <span>Med</span><strong>space</strong> */}
                                    <img src={logo} alt="logo" className="w-48" />
                                    <span className="block w-9"></span>
                                </div>


                                {/* <div className=""><strong>MS</strong></div> */}
                            </a>


                        </div>


                        <SearchComponent searchField={searchField} />
                        {/* <SSEConnectionStatus online={sseStatus} /> */}

                        <div className="flex-1">
                            <ul className="flex flex-row justify-end mt-1">
                                <li className="mx-2 font-sans">
                                    <button id="search"
                                        className="text-xl "
                                    >

                                        <small>welcome,</small>
                                        {state.isAuthenticated ? state.user : ""}
                                    </button>
                                </li>

                                <li className="mx-5">

                                    {/* <button onClick={() => setNotVisible(notVisible => !notVisible)}
                                        className="relative  hover:text-purple-700 ">
                                        <i className="animate-ping absolute block rounded-full w-3 h-3 bg-red-500 z-10 ml-3"></i>
                                        <i className="absolute block rounded-full w-3 h-3 bg-red-500 z-10 ml-3"></i>
                                        <svg className="h-6 mt-1 text-gray-600  hover:text-purple-700 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                                        </svg>


                                        {notVisible ?
                                            <Notification closeMe={() => setNotVisible(false)} /> : ''}

                                    </button> */}

                                    {/* <Menu as="div" className="relative inline-block text-left">
                                        {({ open }) => (
                                            <>
                                                <div>
                                                    <Menu.Button className="relative  hover:text-purple-700 " aria-expanded="false" aria-haspopup="true">
                                                        <i className="animate-ping absolute block rounded-full w-3 h-3 bg-red-500 z-10 ml-3"></i>
                                                        <i className="absolute block rounded-full w-3 h-3 bg-red-500 z-10 ml-3"></i>
                                                        <svg className="h-6 mt-1 text-gray-600  hover:text-purple-700 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                            <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                                                        </svg>
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        static
                                                        className="absolute z-10 right-0  mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                    >


                                                        <Menu.Item className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                            {({ active }) => (
                                                                <Notification />
                                                            )}
                                                        </Menu.Item>


                                                    </Menu.Items>
                                                </Transition>
                                            </>
                                        )}
                                    </Menu> */}

                                </li>


                                {/* <li className="mx-5">
                                    <button onClick={() => dispatch({ type: "LOGOUT" })}
                                        className="text-xl font-bold hover:text-purple-700 " >Logout
                                    <svg className="inline mx-1 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                        </svg>
                                    </button>
                                </li> */}
                                <li>

                                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 ">
                                        {/* <button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                            <span className="sr-only">View notifications</span>
                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                            </svg>
                                        </button> */}

                                        <div className="ml-3 relative">
                                            {/* <div>
                                                <button type="button" onClick={() => setUserMenuVisible(!userMenuVisible)} className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu" aria-expanded="false" aria-haspopup="true">
                                                    <span className="sr-only">Open user menu</span>
                                                    <img className="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                                                </button>
                                            </div> */}

                                            <Menu as="div" className="relative inline-block text-left">
                                                {({ open }) => (
                                                    <>
                                                        <div>
                                                            <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu" aria-expanded="false" aria-haspopup="true">
                                                                {/* <span className="sr-only">Open user menu</span> */}
                                                                <img className="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />

                                                                {/* <ChevronDownIcon
                                                                    className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
                                                                    aria-hidden="true"
                                                                /> */}
                                                            </Menu.Button>
                                                        </div>
                                                        <Transition
                                                            show={open}
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items
                                                                static
                                                                className="absolute z-10 right-0  mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                            >
                                                                <div className="z-20 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                                                                    <Menu.Item className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                                        {({ active }) => (
                                                                            <a href="#" role="menuitem">Your Profile</a>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                                        {({ active }) => (
                                                                            <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Settings</a>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                                                        {({ active }) => (
                                                                            <a href="#" onClick={() => dispatch({ type: "LOGOUT" })} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Sign out</a>
                                                                        )}
                                                                    </Menu.Item>
                                                                </div>

                                                            </Menu.Items>
                                                        </Transition>
                                                    </>
                                                )}
                                            </Menu>

                                            {/* {userMenuVisible ?
                                                <div className="z-20 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Your Profile</a>
                                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Settings</a>
                                                    <a href="#" onClick={() => dispatch({ type: "LOGOUT" })} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Sign out</a>
                                                </div> : null
                                            } */}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>

                </nav>
            </header>

        </>
    )
}
import React, { useEffect, useState } from 'react'
import SellModel from '../Services/Model/SellModel';


export default function SyncStatus() {
    const [unsync, setUnsync] = useState(0)
    useEffect(() => {
        const model = new SellModel();
        model.getUnsyncCount( c => {
            setUnsync(c)
        });

    }, []);
    return (
        <span >{unsync}</span>
    )
}
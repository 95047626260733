import { configureStore } from '@reduxjs/toolkit'
import AddSellReducer from './Reducers/AddSellReducer'
import MasterReducer from './Reducers/MasterReducer'
import OnlineOrdersReducers from './Reducers/OnlineOrdersReducer'
import PrintReducer from './Reducers/PrintReducer'
import SettingsReducer from './Reducers/Settings'
import StockReducer from './Reducers/StockReducer'
import SyncReducer from './Reducers/SyncReducer'
import WhatsappReducer from './Reducers/WhatsappReducer'
import AccountReducer from './Reducers/admin/accountSlice'
export default configureStore({
    reducer: {
        addsell: AddSellReducer,
        addSellPrint: PrintReducer,
        whatsapp: WhatsappReducer,
        master: MasterReducer,
        stockReport: StockReducer,
        sync: SyncReducer,
        settings: SettingsReducer,
        orders: OnlineOrdersReducers,
        account: AccountReducer

    }
})
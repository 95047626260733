import axios from 'axios';
import Dexie from 'dexie'
import { syncTables } from '../../Constants/SyncTable'
import Http from "../Http";
// import { support } from 'jquery';
import { db } from '../../Dexie/db'
class DB
// extends Dexie 
{
    constructor() {
        // super('medspace');
        this.http = new Http();

    }
    async get() {
        var exists = await this.exists();
        if (exists) {
            return this.init();
        } else {
            throw new Error("Database not created yet.");
        }
    }
    async exists() {
        // try {

        return await Dexie.exists("medspace");


    }
    init() {
        // var db = this;
        // //db.close();
        // if (db.isOpen()) {
        //     return db;
        //     //throw new Error("Database is open");
        // }
        // // console.log("openning database");
        // db.version(1).stores({
        //     company: `++id,contact,gstin,email,timestamp`,
        //     items_master: `++id,itemdesc,timestamp`,
        //     stockdetailshistory: `++id,item_id,batchno,barcode,timestamp`,
        //     stock_barcode: `++id,barcode,item_id,[item_id+sold],[barcode+sold],[c_barcode+sold],sdh_id,c_barcode,sold,timestamp`,
        //     item_merge: `++id,item_id,mapped_id,timestamp`,
        //     common_barcode: `++id,item_id,barcode,timestamp`,
        //     syncMemory: `++id,src,sync_id,timestamp`,
        //     drawermaster: `++id,drawer,timestamp`,
        //     selldetails: '++id,item_id,barcode,sell_id,invoice,created_at,timestamp,local_timestamp',
        //     sells: '++id,invoice,order_date,customer,created_at,timestamp,local_timestamp',
        //     sellRegularPost: '++id,invoice,order_date,customer,bill_total,sync',
        //     sell_regular: '++id,invoice,order_date,customer_id,timestamp,sync',
        //     sell_regular_details: '++id,sell_id,item_id,batch,timestamp,sync',
        //     customer: '++id,name,mobile,timestamp,sync',
        //     customer_balance: '++id,cust_id,balance,timestamp',
        //     sellPost: '++id,sync,timestamp',
        //     reward_points: '++id,cust_id,points,timestamp',
        //     redeem_history: '++id,customer_id,invoice,points,timestamp',
        //     reward_settings: '++id,category,percentage,timestamp',
        //     payment_mode: '++id,name,active,timestamp',
        //     settings: '++id,name,value,timestamp',
        //     catmaster: '++id,category,timestamp',
        //     invoice_templates: '++id,template_id,invoice_name,invoice_code,section,timestamp',
        //     invoices: '++id,name,value,timestamp'

        // });
        // db.open();
        return db;
    }

    createDB(callback) {

        Dexie.exists("medspace").then(async (exist) => {
            if (!exist) {
                console.log("Fetching Data from server.");
                // Swal.fire({
                //     backdrop: false,
                //     title: "Fetching Data from server..",
                //     html: "Please wait ...",
                //     //timer: 2000,
                //     timerProgressBar: true,
                //     onBeforeOpen: () => {
                //         Swal.showLoading();
                //     }
                // });
                // this.syncLocalItems(true, callback);
                // this.fetchData(callback);
            } else {
                // await init();
                // syncLocalItems();
            }
        });
    }

    fetchData(callback) {
        // var self = this;
        var i = 1;
        // var j = 10;

        this.asyncForEach(syncTables, async (element) => {
            console.log(element);
            // var params = {
            //     body: new URLSearchParams({
            //         lastItemId: lastItemId,
            //         lastCustomer: lastCustomer,
            //         lastSDHId: lastSDHId,
            //         lastBarcode: lastBarcode,
            //         lastSellDetailsId: lastSellDetailsId,
            //         lastSellId: lastSellId,
            //         unpaidCount: unpaidCount,
            //         lastItemMerge: lastItemMerge,
            //         lastCBID: lastCBID,
            //         lastDrawerId: lastDrawerId,
            //         lastRedeemId: lastRedeemId,
            //         lastRewardHistoryId: lastRewardHistoryId,
            //         lastRewardSettingId: lastRewardSettingId,
            //         sellPostData: sellPostData,
            //         syncMemory: syncMemoryData,
            //         schema: element
            //         //  "_token": _token
            //     })
            // };

            if (element === "selldetails") {
                var db = this.init();
                var sell_id = [];
                var sells = await db.sells.toArray()
                console.log("sells", sells)
                sells.forEach(d => {
                    sell_id.push(d.id)
                })
                console.log("sells id => ", sell_id)
                var formData = new FormData();
                formData.append("table", element);
                formData.append("sell_ids", sell_id)
            } else {
                formData = new FormData();
                formData.append("table", element);
            }


            var response = await axios.post("/api/offline/GetOfflineSchemaData", formData);
            if (response.status === 200) {
                var data = response.data;
                // Dexie.exists("medspace").then(async (exist) => {
                //     if (!exist) {


                //     }else {

                //     }
                //     // db[element].bulkAdd(data[element]);
                // });

                // var db = self.init();
                await this.insertData(element, data);
                callback(i++)
                console.log("table created => ", element)
                console.log("Got response from server", response.data)



            } else {
                // break;
            }


            // const data = await http.Post("/api/getOfflineData", params);
            // // const data =  response.json();
            // //console.log(response);
            // // throw new Error("Response Checking");
            // // response.then(data => {
            // console.log("Loading ", (i * 6) + j, '% ', ' completed');
            // db[element].bulkAdd(data[element]);

            // Swal.update({ html: "Loading " + ((i * 6) + j) + "% completed." })
            // if ((i * 6) + j >= 100) {
            //     Swal.close();
            //     callback(true);
            // }

            // i++;
            // db.item_master.bulkAdd(data.item_master);
            // db.stock_barcode.bulkAdd(data.stock_barcode);
            // db.stockdetailshistory.bulkAdd(data.stockdetailshistory);
            // db.customer.bulkAdd(data.customer);
            // db.sells.bulkAdd(data.sells);
            // db.selldetails.bulkAdd(data.selldetails);
            // db.unpaidCustomers.bulkAdd(data.unpaidCustomers);
            // db.item_merge.bulkAdd(data.item_merge);
            // db.common_barcode.bulkAdd(data.common_barcode);
            // db.drawermaster.bulkAdd(data.drawermaster);                 

            // $("#sync_spinner").hide();
            //console.log(data);
            // console.log(data.item_master)
            // });
        });
    }
    insertData(table, data) {
        // var self = this;
        // var http = this.http;
        // let db = this;
        var db = this.init();
        if (data !== null && data !== "") {

            return db[table].bulkAdd(data);
        }
        return 0;


        // let lastBarcode = 0;
        // let lastCustomer = 0;
        // let lastItemId = 0;
        // let lastItem = 0;
        // let lastSDHId = 0;
        // let lastSBID = 0;
        // let lastSellId = 0;
        // let lastSellDetailsId = 0;
        // let unpaidCount = 0;
        // let lastItemMerge = 0;
        // let lastDrawerId = 0;
        // let lastCBID = 0;
        // let lastRedeemId = 0;
        // let lastRewardHistoryId = 0;
        // let lastRewardSettingId = 0;
        // let lastSettingId = 0;
        // let sellPostData = [];
        // let syncMemoryData = [];
        // let settings = [];
        // db.transaction(
        //     'r',
        //     db.items_master,
        //     db.customer,
        //     db.stockdetailshistory,
        //     db.stock_barcode,
        //     db.sells,
        //     db.selldetails,
        //     // db.unpaidCustomers,
        //     db.item_merge,
        //     db.common_barcode,
        //     db.sellPost,
        //     db.syncMemory,
        //     db.drawermaster,
        //     db.redeem_history,
        //     db.reward_points,
        //     db.reward_settings,
        //     db.customer_balance,
        //     db.payment_mode,
        //     db.catmaster,
        //     db.settings,
        //     // async () => {
        //     //     // db.items_master
        //     //     //     .orderBy("id")
        //     //     //     .last()
        //     //     //     .then(data => {
        //     //     //         if (data != undefined) {
        //     //     //             lastItemId = data.id;
        //     //     //         }
        //     //     //     });
        //     //     // db.reward_settings
        //     //     //     .orderBy("id")
        //     //     //     .last()
        //     //     //     .then(data => {
        //     //     //         if (data != undefined) {
        //     //     //             lastRewardSettingId = data.id;
        //     //     //         }
        //     //     //     });
        //     //     // db.redeem_history
        //     //     //     .orderBy("id")
        //     //     //     .last()
        //     //     //     .then(data => {
        //     //     //         if (data != undefined) {
        //     //     //             lastRedeemId = data.id;
        //     //     //         }
        //     //     //     });
        //     //     // db.reward_points
        //     //     //     .orderBy("id")
        //     //     //     .last()
        //     //     //     .then(data => {
        //     //     //         if (data != undefined) {
        //     //     //             lastRewardHistoryId = data.id;
        //     //     //         }
        //     //     //     });
        //     //     // db.customer
        //     //     //     .orderBy("id")
        //     //     //     .last()
        //     //     //     .then(data => {
        //     //     //         if (data != undefined) {
        //     //     //             lastCustomer = data.id;
        //     //     //         }
        //     //     //     });
        //     //     // db.stockdetailshistory
        //     //     //     .orderBy("id")
        //     //     //     .last()
        //     //     //     .then(data => {
        //     //     //         if (data != undefined) {
        //     //     //             lastSDHId = data.id;
        //     //     //         }
        //     //     //     });
        //     //     // db.stock_barcode
        //     //     //     .orderBy("barcode")
        //     //     //     .last()
        //     //     //     .then(data => {
        //     //     //         if (data != undefined) {
        //     //     //             lastBarcode = data.barcode;
        //     //     //         }
        //     //     //     });
        //     //     // db.sells
        //     //     //     .orderBy("id")
        //     //     //     .last()
        //     //     //     .then(data => {
        //     //     //         if (data != undefined) {
        //     //     //             lastSellId = data.id;
        //     //     //         }
        //     //     //     });
        //     //     // db.selldetails
        //     //     //     .orderBy("id")
        //     //     //     .last()
        //     //     //     .then(data => {
        //     //     //         if (data != undefined) {
        //     //     //             lastSellDetailsId = data.id;
        //     //     //         }
        //     //     //     });
        //     //     // db.unpaidCustomers
        //     //     //     .toCollection()
        //     //     //     .count()
        //     //     //     .then(data => {
        //     //     //         if (data != 0) {
        //     //     //             unpaidCount = data;
        //     //     //         }
        //     //     //     });
        //     //     // db.item_merge
        //     //     //     .orderBy("id")
        //     //     //     .last()
        //     //     //     .then(data => {
        //     //     //         if (data != undefined) {
        //     //     //             lastItemMerge = data.id;
        //     //     //         }
        //     //     //     });
        //     //     // db.common_barcode
        //     //     //     .orderBy("id")
        //     //     //     .last()
        //     //     //     .then(data => {
        //     //     //         if (data != undefined) {
        //     //     //             lastCBID = data.id;
        //     //     //         }
        //     //     //     });
        //     //     // db.sellPost.toArray(data => {
        //     //     //     sellPostData = data;
        //     //     // });
        //     //     // db.drawermaster
        //     //     //     .orderBy("id")
        //     //     //     .last()
        //     //     //     .then(data => {
        //     //     //         if (data != undefined) {
        //     //     //             lastDrawerId = data.id;
        //     //     //         }
        //     //     //     });
        //     //     // db.settings
        //     //     //     .orderBy("id")
        //     //     //     .last()
        //     //     //     .then(data => {
        //     //     //         if (data != undefined) {
        //     //     //             lastSettingId = data.id;
        //     //     //         }
        //     //     //     });
        //     //     // db.syncMemory.toArray(data => {
        //     //     //     syncMemoryData = data;
        //     //     // });
        //     // }
        // ).then(function () {
        //     // db.syncMemory.clear();
        //     console.log("Inserting Data")
        //     db[table].bulkAdd(data);

        //     // if (bulk) {


        //     //     // Swal.close();
        //     // }
        //     // else {

        //     //     var params = {
        //     //         body: new URLSearchParams({
        //     //             lastItemId: lastItemId,
        //     //             lastCustomer: lastCustomer,
        //     //             lastSDHId: lastSDHId,
        //     //             lastBarcode: lastBarcode,
        //     //             lastSellDetailsId: lastSellDetailsId,
        //     //             lastSellId: lastSellId,
        //     //             unpaidCount: unpaidCount,
        //     //             lastItemMerge: lastItemMerge,
        //     //             lastCBID: lastCBID,
        //     //             lastDrawerId: lastDrawerId,
        //     //             sellPostData: sellPostData,
        //     //             syncMemory: syncMemoryData,

        //     //             //  "_token": _token
        //     //         })
        //     //     };
        //     //     const response = http.Post("/api/getOfflineData", params);
        //     //     response.then(data => {
        //     //         this.updateTables("stockdetailshistory", data);
        //     //         // updateTables('stock_barcode', data);
        //     //         // updateTables('customer', data);
        //     //         // updateTables('item_merge', data);
        //     //         // updateTables('item_master', data);
        //     //         // updateTables('common_barcode', data);
        //     //         // updateTables('sells', data);
        //     //         // updateTables('selldetails', data);
        //     //         // updateTables('drawermaster', data);
        //     //         db.sellPost.clear();
        //     //         db.sells.where({ temp: 1 }).delete();
        //     //         db.selldetails.where({ temp: 1 }).delete();
        //     //         //getDailySells(currentDate);
        //     //         // $.each(data.unpaidCustomers, (k, v) => {
        //     //         //     //console.log(v);
        //     //         //     db.unpaidCustomers.put(v);
        //     //         //     db.syncMemory.add({ src: "unpaidCustomers", sync_id: v.cust_id, timestamp: v.updated_at });
        //     //         // })
        //     //         //db.unpaidCustomers.where()
        //     //     });
        //     // }



        //     // $.ajax({
        //     //     url: '/api/getOfflineData',
        //     //     data: {
        //     //         "lastItemId": lastItemId,
        //     //         "lastCustomer": lastCustomer,
        //     //         "lastSDHId": lastSDHId,
        //     //         "lastBarcode": lastBarcode,
        //     //         "lastSellDetailsId": lastSellDetailsId,
        //     //         "lastSellId": lastSellId,
        //     //         "unpaidCount": unpaidCount,
        //     //         "lastItemMerge": lastItemMerge,
        //     //         "lastCBID": lastCBID,
        //     //         "lastDrawerId": lastDrawerId,
        //     //         "sellPostData": sellPostData,
        //     //         "syncMemory": syncMemory,
        //     //         "_token": _token

        //     //     },

        //     //     type: 'post',
        //     //     dataType: 'json',
        //     //     success: function (data) {
        //     //         if (bulk) {
        //     //             db.item_master.bulkAdd(data.item_master);
        //     //             db.stock_barcode.bulkAdd(data.stock_barcode);
        //     //             db.stockdetailshistory.bulkAdd(data.stockdetailshistory);
        //     //             db.customer.bulkAdd(data.customer);
        //     //             db.sells.bulkAdd(data.sells);
        //     //             db.selldetails.bulkAdd(data.selldetails);
        //     //             db.unpaidCustomers.bulkAdd(data.unpaidCustomers);
        //     //             db.item_merge.bulkAdd(data.item_merge);
        //     //             db.common_barcode.bulkAdd(data.common_barcode);
        //     //             db.drawermaster.bulkAdd(data.drawermaster);
        //     //         } else {

        //     //             updateTables('stockdetailshistory', data);
        //     //             updateTables('stock_barcode', data);
        //     //             updateTables('customer', data);
        //     //             updateTables('item_merge', data);
        //     //             updateTables('item_master', data);
        //     //             updateTables('common_barcode', data);
        //     //             updateTables('sells', data);
        //     //             updateTables('selldetails', data);
        //     //             updateTables('drawermaster', data);
        //     //             db.sellPost.clear();
        //     //             db.sells.where({ temp: 1 }).delete();
        //     //             db.selldetails.where({ temp: 1 }).delete();
        //     //             getDailySells(currentDate);
        //     //             $.each(data.unpaidCustomers, (k, v) => {
        //     //                 //console.log(v);
        //     //                 db.unpaidCustomers.put(v);
        //     //                 db.syncMemory.add({ src: "unpaidCustomers", sync_id: v.cust_id, timestamp: v.updated_at });
        //     //             })
        //     //             //db.unpaidCustomers.where()

        //     //         }
        //     //         Swal.close();
        //     //         $("#sync_spinner").hide();
        //     //         //console.log(data);

        //     //     }
        //     // })

        // }).catch(e => {
        //     console.log("Transaction Error:", e);
        // });

    }

    async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    // updateTables(table, data) {
    //     data[table].forEach(element => {
    //         //console.log(element);
    //     });
    //     // $.forEach(data[table], (k, v) => {
    //     //      console.log(k);
    //     //      console.log(v);
    //     //     // db[table].get(v.id,(data)=>{
    //     //     //   if(data === undefined){
    //     //     //   console.log("Record Doesn't Exists.");
    //     //     //       db[table].add(v);
    //     //     //   }else{
    //     //     //  console.log("Record Exists.");
    //     //     //  console.log(v);

    //     //     db[table].put(v).then((data) => {
    //     //         //      console.log("Records Update"+data);
    //     //     });
    //     // db.syncMemory.add({ src: table, sync_id: v.id, timestamp: v.updated_at });
    //     //   }
    //     //});
    //     //});
    // }
}












export default DB;



// export default class DB extends Dexie {
//     constructor(){
//         super('medspace')
//         this.init();
//     }

//     openDB() {
//         // this.close();
//         this.version(1).stores({
//             item_master: `++id,itemdesc,sync`,
//             stockdetailshistory: `++id,item_id,sdh_id,sync`,
//             stock_barcode: `++id,barcode,item_id,[item_id+sold],[barcode+sold],[c_barcode+sold],sdh_id,c_barcode,sold,sync`,
//             item_merge: `++id,item_id,mapped_id,sync`,
//             common_barcode: `++id,item_id,barcode,sync`,
//             syncMemory: `++id,src,sync_id,timestamp`,
//             drawermaster: `++id,drawer`,
//             selldetails: '++id,item_id,barcode,sell_id,invoice,created_at,sync,temp',
//             sells: '++id,invoice,order_date,customer,created_at,sync,temp',
//             customer: '++id,name,mobile,sync',
//             unpaidCustomers: '&cust_id,sync',
//             sellPost: '++id,sync,data'
//         });
//         this.open().catch(function (e) {
//             console.error("Open failed: " + e.stack);
//         })
//        // this.open();
//     }
//     init() {
//         var db = this;
//         Dexie.exists("medspace").then(exist => {
//             if (!exist) {
//                 console.log("Fetching Data from server.");
//                 Swal.fire({
//                     title: "Fetching Data from server..",
//                     html: "Please wait ...",
//                     //timer: 2000,
//                     timerProgressBar: true,
//                     onBeforeOpen: () => {
//                         Swal.showLoading();
//                     }
//                 });
//                 db.openDB();
//                 db.syncLocalItems(true);
//             } else {
//                 db.openDB();
//                 db.syncLocalItems();
//             }
//         });
//     }

//     syncLocalItems(bulk = false) {
//         let db = this;
//         let lastBarcode = 0;
//         let lastCustomer = 0;
//         let lastItemId = 0;
//         let lastItem = 0;
//         let lastSDHId = 0;
//         let lastSBID = 0;
//         let lastSellId = 0;
//         let lastSellDetailsId = 0;
//         let unpaidCount = 0;
//         let lastItemMerge = 0;
//         let lastDrawerId = 0;
//         let lastCBID = 0;
//         let sellPostData = [];
//         let syncMemoryData = [];
//         db.transaction(
//             'r',
//             db.item_master,
//             db.customer,
//             db.stockdetailshistory,
//             db.stock_barcode,
//             db.sells,
//             db.selldetails,
//             db.unpaidCustomers,
//             db.item_merge,
//             db.common_barcode,
//             db.sellPost,
//             db.syncMemory,
//             db.drawermaster,
//            async () => {
//                 db.item_master
//                     .orderBy("id")
//                     .last()
//                     .then(data => {
//                         if (data != undefined) {
//                             lastItemId = data.id;
//                         }
//                     });
//                 db.customer
//                     .orderBy("id")
//                     .last()
//                     .then(data => {
//                         if (data != undefined) {
//                             lastCustomer = data.id;
//                         }
//                     });
//                 db.stockdetailshistory
//                     .orderBy("id")
//                     .last()
//                     .then(data => {
//                         if (data != undefined) {
//                             lastSDHId = data.id;
//                         }
//                     });
//                 db.stock_barcode
//                     .orderBy("barcode")
//                     .last()
//                     .then(data => {
//                         if (data != undefined) {
//                             lastBarcode = data.barcode;
//                         }
//                     });
//                 db.sells
//                     .orderBy("id")
//                     .last()
//                     .then(data => {
//                         if (data != undefined) {
//                             lastSellId = data.id;
//                         }
//                     });
//                 db.selldetails
//                     .orderBy("id")
//                     .last()
//                     .then(data => {
//                         if (data != undefined) {
//                             lastSellDetailsId = data.id;
//                         }
//                     });
//                 db.unpaidCustomers
//                     .toCollection()
//                     .count()
//                     .then(data => {
//                         if (data != 0) {
//                             unpaidCount = data;
//                         }
//                     });
//                 db.item_merge
//                     .orderBy("id")
//                     .last()
//                     .then(data => {
//                         if (data != undefined) {
//                             lastItemMerge = data.id;
//                         }
//                     });
//                 db.common_barcode
//                     .orderBy("id")
//                     .last()
//                     .then(data => {
//                         if (data != undefined) {
//                             lastCBID = data.id;
//                         }
//                     });
//                 db.sellPost.toArray(data => {
//                     sellPostData = data;
//                 });
//                 db.drawermaster
//                     .orderBy("id")
//                     .last()
//                     .then(data => {
//                         if (data != undefined) {
//                             lastDrawerId = data.id;
//                         }
//                     });
//                 db.syncMemory.toArray(data => {
//                     syncMemory = data;
//                 });
//             }
//         ).then(function() {
//             db.syncMemory.clear();
//             var params = {
//                 body: new URLSearchParams({
//                     lastItemId: lastItemId,
//                     lastCustomer: lastCustomer,
//                     lastSDHId: lastSDHId,
//                     lastBarcode: lastBarcode,
//                     lastSellDetailsId: lastSellDetailsId,
//                     lastSellId: lastSellId,
//                     unpaidCount: unpaidCount,
//                     lastItemMerge: lastItemMerge,
//                     lastCBID: lastCBID,
//                     lastDrawerId: lastDrawerId,
//                     sellPostData: sellPostData,
//                     syncMemory: syncMemory
//                     //  "_token": _token
//                 })
//             };
//             const response = Http.Post("/api/getOfflineData", params);
//             response.then(data => {
//                 if (bulk) {
//                     db.item_master.bulkAdd(data.item_master);
//                     db.stock_barcode.bulkAdd(data.stock_barcode);
//                     db.stockdetailshistory.bulkAdd(data.stockdetailshistory);
//                     db.customer.bulkAdd(data.customer);
//                     db.sells.bulkAdd(data.sells);
//                     db.selldetails.bulkAdd(data.selldetails);
//                     db.unpaidCustomers.bulkAdd(data.unpaidCustomers);
//                     db.item_merge.bulkAdd(data.item_merge);
//                     db.common_barcode.bulkAdd(data.common_barcode);
//                     db.drawermaster.bulkAdd(data.drawermaster);
//                 } else {
//                     db.updateTables("stockdetailshistory", data);
//                     // updateTables('stock_barcode', data);
//                     // updateTables('customer', data);
//                     // updateTables('item_merge', data);
//                     // updateTables('item_master', data);
//                     // updateTables('common_barcode', data);
//                     // updateTables('sells', data);
//                     // updateTables('selldetails', data);
//                     // updateTables('drawermaster', data);
//                     db.sellPost.clear();
//                     db.sells.where({ temp: 1 }).delete();
//                     db.selldetails.where({ temp: 1 }).delete();
//                     //getDailySells(currentDate);
//                     // $.each(data.unpaidCustomers, (k, v) => {
//                     //     //console.log(v);
//                     //     db.unpaidCustomers.put(v);
//                     //     db.syncMemory.add({ src: "unpaidCustomers", sync_id: v.cust_id, timestamp: v.updated_at });
//                     // })
//                     //db.unpaidCustomers.where()
//                 }
//                 Swal.close();
//                 // $("#sync_spinner").hide();
//                 //console.log(data);
//                 // console.log(data.item_master)
//             });

//             // $.ajax({
//             //     url: '/api/getOfflineData',
//             //     data: {
//             //         "lastItemId": lastItemId,
//             //         "lastCustomer": lastCustomer,
//             //         "lastSDHId": lastSDHId,
//             //         "lastBarcode": lastBarcode,
//             //         "lastSellDetailsId": lastSellDetailsId,
//             //         "lastSellId": lastSellId,
//             //         "unpaidCount": unpaidCount,
//             //         "lastItemMerge": lastItemMerge,
//             //         "lastCBID": lastCBID,
//             //         "lastDrawerId": lastDrawerId,
//             //         "sellPostData": sellPostData,
//             //         "syncMemory": syncMemory,
//             //         "_token": _token

//             //     },

//             //     type: 'post',
//             //     dataType: 'json',
//             //     success: function (data) {
//             //         if (bulk) {
//             //             db.item_master.bulkAdd(data.item_master);
//             //             db.stock_barcode.bulkAdd(data.stock_barcode);
//             //             db.stockdetailshistory.bulkAdd(data.stockdetailshistory);
//             //             db.customer.bulkAdd(data.customer);
//             //             db.sells.bulkAdd(data.sells);
//             //             db.selldetails.bulkAdd(data.selldetails);
//             //             db.unpaidCustomers.bulkAdd(data.unpaidCustomers);
//             //             db.item_merge.bulkAdd(data.item_merge);
//             //             db.common_barcode.bulkAdd(data.common_barcode);
//             //             db.drawermaster.bulkAdd(data.drawermaster);
//             //         } else {

//             //             updateTables('stockdetailshistory', data);
//             //             updateTables('stock_barcode', data);
//             //             updateTables('customer', data);
//             //             updateTables('item_merge', data);
//             //             updateTables('item_master', data);
//             //             updateTables('common_barcode', data);
//             //             updateTables('sells', data);
//             //             updateTables('selldetails', data);
//             //             updateTables('drawermaster', data);
//             //             db.sellPost.clear();
//             //             db.sells.where({ temp: 1 }).delete();
//             //             db.selldetails.where({ temp: 1 }).delete();
//             //             getDailySells(currentDate);
//             //             $.each(data.unpaidCustomers, (k, v) => {
//             //                 //console.log(v);
//             //                 db.unpaidCustomers.put(v);
//             //                 db.syncMemory.add({ src: "unpaidCustomers", sync_id: v.cust_id, timestamp: v.updated_at });
//             //             })
//             //             //db.unpaidCustomers.where()

//             //         }
//             //         Swal.close();
//             //         $("#sync_spinner").hide();
//             //         //console.log(data);

//             //     }
//             // })
//         }).catch( e => {
//             console.log("Transaction Error:" , e);
//         });
//     }

//     updateTables(table, data) {
//         data[table].forEach(element => {
//             //console.log(element);
//         });
//         // $.each(data[table], (k, v) => {
//         //      console.log(k);
//         //      console.log(v);
//         //     // db[table].get(v.id,(data)=>{
//         //     //   if(data === undefined){
//         //     //   console.log("Record Doesn't Exists.");
//         //     //       db[table].add(v);
//         //     //   }else{
//         //     //  console.log("Record Exists.");
//         //     //  console.log(v);

//         //     db[table].put(v).then((data) => {
//         //         //      console.log("Records Update"+data);
//         //     });
//         // db.syncMemory.add({ src: table, sync_id: v.id, timestamp: v.updated_at });
//         //   }
//         //});
//         //});
//     }

// }
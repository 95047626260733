import Swal from "sweetalert2";
import { db } from "../Dexie/db";

export const today = () => {
    return new Date()
        .toISOString()
        .split("T")[0];
}

export const getToken = () => {
    return localStorage.getItem("_token");
}
export const timestamp = () => {
    return new Date().getTime();
}
export const getLatestTimestamp = async (table) => {
    var d = await db[table].orderBy('timestamp').last();
    if (d === undefined) {
        return new Date().getTime();
    }
    console.log(d)
    return d.timestamp;
}
export const Confirm = (callback) => {
    return Swal.fire("Are you sure ?")
}
export const generateInvoice = async(db) => {
                let usercode = localStorage.getItem("_usercode");
                let lastInvoice = await db.sell_regular.orderBy('id').last();
                // invoice  be in format of 1ER-101
                // first part beforw hyphen is usercode and second part is invoice number
                if (lastInvoice !== undefined) {
                  let invoiceComp = lastInvoice.invoice.split("-")
                    lastInvoice.invoice = parseInt(invoiceComp[1]);
                }
                // return response;
                var invoice = lastInvoice !== undefined ? ++lastInvoice.invoice : 1;
             
                return usercode + "-" + invoice;
}
export const validate = (event, type, callback) => {
    switch (type) {
        case "qnty":
            const maxQnty = event.target.getAttribute("max");
            console.log(parseInt(event.target.value), " - ", maxQnty, event.target.value > maxQnty)
            if (parseInt(event.target.value) > maxQnty) {
                // event.preventDefault();
                return;
            }
            if (event.target.value === '') {
                // event.preventDefault();
                event.target.value = 0
                // return;
            }
            // alert(maxQnty);
            callback()
            break;
        case "disc":
            if (event.target.value === '') {
                event.target.value = 0;
            }
            callback()
            break;
        case "billno":
            if (event.target.value === '' || event.target.value === '') {
                return
            }
            callback()
            break;
        case "dealer":
            if (event.target.value === '' || event.target.value === '') {
                return
            }
            callback()
            break;
        case "not-required":

            callback()
            break;

        default:
            break;
    }
}

export const CalculateCouchDBSyncPercentage = (info) => {
    let read = info.change.docs_read;
    let total = info.change.docs_read + info.change.pending;
    let percentage = (read / total) * 100;
    return percentage
    }
import React, { Suspense, useEffect, useState } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
// import DailySellGraph from "./Dashboard/DailySellGraph"

// import { AuthContext } from "../Context/AuthContext"
import Footer from "../Includes/Footer"
import Header from "../Includes/Header"
import HeaderMenu from "../Includes/HeaderMenu"
// import Sell from "../Sell"
import HomePageLoading from "./HomePageLoading"
import DB from "../Services/Model/DB"
import { sync } from "../Services/Sync"
import { syncTables } from '../Constants/SyncTable'
import OfflineDetector from "../GlobalComponent/OfflineDetector.js"
// import Layout from "../Includes/Layout"


const Layout = React.lazy(() => import("../Includes/Layout.js"));

const OnlineOrders = React.lazy(() => import("./OnlineOrders.js"));
const Dashboard = React.lazy(() => import("./Dashboard.js"));
const DrawerPage = React.lazy(() => import("./DrawerPage.js"));
const ExpiryPage = React.lazy(() => import("./ExpiryPage.js"));
const MasterSearch = React.lazy(() => import("./MasterSearch"));
const Whatsapp = React.lazy(() => import("./Whatsapp"));
const MasterPage = React.lazy(() => import("./MasterPage.js"));
const Admin = React.lazy(() => import("./Admin"));

const GstPage = React.lazy(() => import("./GstPage.js"));
const PaymentsPage = React.lazy(() => import("./PaymentsPage.js"));
const SettingsPage = React.lazy(() => import("./SettingsPage.js"));
const StockPage = React.lazy(() => import("./StockPage.js"));
const StockReport = React.lazy(() => import("./StockReport.js"));

export default function Home() {

    // const { state, dispatch } = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false);
    const [loadingPer, setLoadingPer] = useState(0)
    useEffect(() => {
        // const db = new DB();
        // db.exists().then(d => {
        //     if (d) {

        //         setTimeout(() => {
        //             // sync();
        //             setIsLoading(false);
        //         }, 0);
        //     } else {
        //         db.createDB(d => {
        //             var total = syncTables.length;
        //             var per = parseInt(d / total * 100);
        //             setLoadingPer(per)
        //             console.log("Table Index ", d);
        //             if (per === 100) {
        //                 setIsLoading(false)
        //             }

        //         });
        //     }
        // }).catch(err => {
        //     console.log("Caught error ", err)
        // })




    }, [])

    return (

        <div className="font-sans text-gray-600">

            {isLoading ?
                <HomePageLoading loadingPer={loadingPer} />
                :
                <BrowserRouter>
                    <Header />
                    <OfflineDetector>
                        <HeaderMenu />
                    </OfflineDetector>
                    <div className="h-full">
                        <Switch>
                            <Route exact path="/sell">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Layout />
                                </Suspense>
                            </Route>
                            <Route exact path="/">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Layout />
                                </Suspense>
                            </Route>
                            <Route path="/masterSearch/:type/:id" >
                                <Suspense fallback={<div>Loading...</div>}>
                                    <MasterSearch />
                                </Suspense>
                            </Route>



                            <Route exact path="/admin">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Admin />
                                </Suspense>
                            </Route>
                            <Route exact path="/orders">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <OnlineOrders />
                                </Suspense>
                            </Route>

                            <Route exact path="/importStock">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <StockPage />
                                </Suspense>
                            </Route>
                            <Route exact path="/payment">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <PaymentsPage />
                                </Suspense>
                            </Route>
                            <Route exact path="/drawer">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <DrawerPage />
                                </Suspense>
                            </Route>
                            <Route exact path="/expiry">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ExpiryPage />
                                </Suspense>
                            </Route>
                            <Route exact path="/masters">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <MasterPage />
                                </Suspense>
                            </Route>
                            <Route exact path="/dashboard">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Dashboard />
                                </Suspense>
                            </Route>
                            <Route exact path="/whatsapp">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Whatsapp />
                                </Suspense>
                            </Route>
                            <Route exact path="/gst">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <GstPage />
                                </Suspense>
                            </Route>
                            <Route exact path="/settings">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <SettingsPage />
                                </Suspense>
                            </Route>
                            <Route exact path="/stock-report">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <StockReport />
                                </Suspense>
                            </Route>
                        </Switch>
                    </div>
                    <Footer />
                </BrowserRouter >
            }

        </div>

    )
}
import DB from "./DB";
import Http from "../../Services/Http";
import { generateInvoice, getLatestTimestamp, timestamp, today } from "../utility";
export default class SellModel {
    constructor() {
        // super();
        this.http = new Http();
        this.db = new DB();
    }

    async addCustomer({ customer_name, customer_contact, customer_address }) {
        var db = this.db.init();
        const lastCustomer = await db.customer.reverse().first();
        var result = await db.customer.add(
            {
                name: customer_name,
                mobile: customer_contact,
                address: customer_address,
                sync: 0,
                timestamp: lastCustomer?.timestamp || 0,


            });
        return result;

    }
    async getCustomerDiscount(itemId, customerId) {
        console.log(itemId, customerId)
        var db = this.db.init();
        const customer_discount = await db.transaction('r', db.customer_discount,
            async () => {
                // console.log(customerId);
                // console.log(itemId);
                // where({ customer_id: customerId })
                return await db.customer_discount.where("item_id").anyOf(itemId).and(item => item.customer_id === customerId).toArray();
            });
        return customer_discount
    }
    async getCustomerBalance(customerId) {
        var db = this.db.init();
        var customerBalance = await db.customer_balance.get({ cust_id: customerId });
        // console.log(customerBalance.wallet_amt);
        return customerBalance;
    }
    async getCurrentInvoiceFormat() {
        var db = this.db.init();
        var result = await db.settings.get({ name: "invoice_bill_format" });
        return result;
    }
    async saveSetting(data) {

    }
    async getInvoiceSetting() {
        var db = this.db.init();
        await db.settings.get({ "name": "" });
    }
    async getItemDetailsFromBarcode(barcode) {
        var db = this.db.init();
        try {

            if (!isNaN(barcode) && barcode !== "") {
                var bar = parseInt(barcode);
                var sdh = await db.stockdetailshistory.get({ barcode: bar });
                if (sdh !== undefined) {
                    var itemData = await db.items_master.get({ id: sdh.item_id });
                    sdh.itemdesc = itemData.itemdesc;
                    return sdh;
                }
            }

        } catch (error) {
            console.log(error)
            return undefined
        }

    }

    getProductCommonBarcode(barcode, barcodeMemory, callback) {
        var db = this.db.init();
        var i = 0;
        db.common_barcode.get({ barcode: barcode + "" }, (d) => {
            if (d !== undefined) {
                db.stock_barcode.where({ c_barcode: d.id + "", sold: "0" }).toArray((sb) => {
                    for (i = 0; i < sb.length; i++) {
                        if (barcodeMemory.find(item => item === sb[i].barcode) === undefined) {
                            this.getProduct(sb[i].barcode, callback);
                            return;

                        }

                    }
                    callback({ barcode: undefined });

                })
            } else {
                callback({ barcode: undefined });
            }

        })
    }

    getProduct(barcode, callback) {
        let result = {};
        // var db = this;
        var db = this.db.init();
        var inBarcode = parseInt(barcode)
        if (!isNaN(inBarcode))
            db.stock_barcode.get({ barcode: inBarcode, sold: 0 }).then(sb => {
                if (sb !== undefined) {
                    db.stockdetailshistory
                        .get({ id: parseInt(sb.sdh_id) })
                        .then(sdh => {
                            db.items_master
                                .get({ id: parseInt(sdh.item_id) })
                                .then(im => {

                                    db.reward_settings.get({ category: im.cat }, (data) => {
                                        if (data === undefined) {
                                            data = {};
                                            data.percentage = 0;
                                        }


                                        result.id = 1;
                                        result.barcode = parseInt(sb.barcode);
                                        result.expdate = sdh.expDate;
                                        result.item_id = im.id;
                                        result.itemdesc = im.itemdesc;
                                        result.mrp = parseFloat(sdh.mrp);
                                        result.rate = parseFloat(sdh.rate);
                                        result.reward = parseFloat(data.percentage);
                                        result.ru = 0;
                                        result.qnty = 1;
                                        result.disc = 0;
                                        result.total = result.mrp;
                                        callback(result);
                                    });


                                });
                        });
                } else {
                    callback(result);
                }

            });

    }
    execRes(data, db) {
        db.sellPost.put({ sync: 0, data: JSON.stringify(data), timestamp: data.invoice + "" }).then(() => {
        });

        if (data.customerData.customer_id === "1" && data.customerData.customer_name !== "") {
            db.customer.add(
                {
                    customer_id: "",
                    email: '',
                    mobile: data.customerData.customer_mobile,
                    active: "1",
                    address: "",
                    city: null,
                    created_at: new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " "),
                    name: data.customerData.customer_name,
                    postal: "",
                    qrcode: null,
                    state: null,
                    sync: "0",

                }).then((customer_id) => {
                    db.customer_balance.add({ cust_id: customer_id + "", balance: 0 + "", sync: 0 });
                    data.customerData.customer_id = customer_id;
                    this.sellInsert(data, db);
                });
        } else {
            this.sellInsert(data, db);
        }
    }
    addSell(data, success) {
        var params = {};
        // console.log(data);
        params.body = JSON.stringify(data);

        data.sellTotal = 0;
        data.barcodeData.forEach(v => {
            data.sellTotal += v.total;
        });

        // console.log("Sell Total =>", data.sellTotal);

        // var db = this;
        var db = this.db.init();
        db.transaction(
            "rw",
            db.sellPost,
            db.stock_barcode,
            db.sells,
            db.selldetails,
            db.reward_points,
            db.redeem_history,
            db.customer,
            db.customer_balance,
            async () => {

                db.sells.orderBy("invoice").last().then(d => {
                    console.log("Invoice from indexed db=>", d)
                    if (data !== undefined) {

                        data.invoice = (d.invoice + 1)
                        this.execRes(data, db)
                    }

                });
                // if (sells === undefined) {

                // })
                // } else {
                //     console.log(sells)
                //     // data.invoice = sells.invoice;


                // }


                // db.sells
                //     .orderBy(":id")
                //     // .reverse()
                //     .last(lastSell => {
                //         parseInt(lastSell.invoice);


                //         console.log(data);
                //     });
                // db.selldetails.putBulk();
            }
        ).then(d => {
            // console.log("Invoice data", d);
            success(data.invoice);
            // alert("Success");
        });
        // console.log(params);
        // var db = this;
        // db.
        // console.log("From Sell Model",params);
        // this.http.Post('/api/sell/addsell',params,'json')
    }
    async addSellRegular({ billDetails, itemDetails }) {
        // var params = {};
        var userId = localStorage.getItem("_userId");
        // params.body = JSON.stringify(data);
        // data.sellTotal = 0;
        // data.barcodeData.map(v => {
        //     data.sellTotal += v.total;
        // });


        // var db = this;
        var db = this.db.init();
        const response = await db.transaction(
            "rw",
            db.sellRegularPost,
            db.stockdetailshistory,
            db.sell_regular,
            db.sell_regular_details,
            db.customer_balance,
            async () => {
                const lastInvoice = await db.sell_regular.orderBy('id').last();
                // console.log("LatInvoice => ", lastInvoice)
                var invoice = lastInvoice?.id || 0;
                var finalInvoiceNo = parseInt(userId + "" + (++invoice))
                // console.log("LatInvoice => ", finalInvoiceNo)

                // var total = 0;
                // for (let index = 0; index < itemDetails.length; index++) {
                //     const element = itemDetails[index];
                // }
                await db.sellRegularPost.put({
                    invoice: billDetails.invoice,
                    order_date: billDetails.invoice_date,
                    customer: billDetails.customer_name,
                    bill_total: billDetails.total,
                    bill_details: billDetails,
                    item_details: itemDetails,
                    sync: 0,
                    timestamp: await timestamp()
                });

                if (billDetails.customer_id === 1 && billDetails.customer_name !== "") {
                    billDetails.customer_id = billDetails.new_customer_id;
                }
                const lastSell = await db.sell_regular.reverse().first();
                billDetails.timestamp = lastSell?.timestamp || 0;
                billDetails.sync = 0;
                // console.log(billDetails)
                const sellId = await db.sell_regular.add(billDetails)
                var itemDetailsTemp = [];
                // var billTotal = 0
                itemDetails.forEach(d => {
                    // billTotal += d.total;
                    itemDetailsTemp.push({
                        batch: d.batchno,
                        disc: d.disc,
                        expiry: d.expdate,
                        gst: d.gst,
                        item_id: d.item_id,
                        mrp: d.mrp,
                        qnty: d.qnty,
                        sell_id: sellId,
                        serial: d.serial,
                        total: d.total,
                        sync: 0
                    })
                })
                await db.sell_regular_details.bulkAdd(itemDetailsTemp);
                itemDetails.map(async d => {
                    await db.stockdetailshistory.where({ item_id: d.item_id, batch_no: d.batchno }).modify(function (value) {
                        // this.value = new Friend({ name: "Another Friend" });c
                        this.value.qnty = this.value.qnty - parseInt(d.qnty);
                    });
                })
                // console.log("Bill-Details ", billDetails)
                var custBal = await db.customer_balance.get({ cust_id: billDetails.customer_id });
                if (custBal !== undefined) {
                    await db.customer_balance.where({ cust_id: billDetails.customer_id }).modify(function (value) {
                        value.balance = ((billDetails.total + billDetails.customer_balance) - billDetails.paid_amt);
                        console.log("UseWallet => ", billDetails.useWallet)
                        if (billDetails.useWallet == true) {

                            value.wallet_amt = 0;
                        }
                    });
                } else {
                    await db.customer_balance.put(
                        {
                            cust_id: billDetails.customer_id,
                            balance: ((billDetails.total + billDetails.customer_balance) - billDetails.paid_amt),
                            timestamp: await getLatestTimestamp("customer_balance")
                        }
                    );
                }

                // return response;
                return finalInvoiceNo;


            }
        )
            .catch(err => {
                console.log(err);
            });
        return response;
    }
    async getLastInvoiceRegular() {
        var db = this.db.init();
        const response = await db.transaction(
            "r",
            db.sell_regular,
            async () => {
                return generateInvoice(db)


            }
        );
        return response;
    }
    async getInvoiceDetailsRegular(invoice) {
        var db = this.db.init();
        var output = {};
        const response = await db.transaction('r',
            db.sell_regular,
            db.sell_regular_details,
            db.customer,
            db.items_master,
            async () => {
                var sell = await db.sell_regular.where("invoice").equals(invoice).first();
                console.log(sell)
                if (sell !== undefined) {
                    var customer = await db.customer.get(sell.customer_id);
                    output.bill_details = sell;
                    output.customer = customer;
                    var itemDetails = [];
                    await db.sell_regular_details.where({ sell_id: sell.id }).each(async d => {
                        var items = await db.items_master.get(d.item_id);
                        d.itemdesc = items.itemdesc;
                        d.batchno = d.batch;
                        // d.mrp = items.mrp;


                        itemDetails.push(d);


                    });
                    output.item_details = itemDetails

                    return output;



                }
                return {};

            }
        );
        return response;
    }
    async getCompanyDetails() {
        var db = this.db.init();
        const response = await db.transaction('r',
            db.company,
            db.settings,
            async () => {
                var company = await db.settings.toArray();
                var compDetails = {}
                company.forEach(element => {
                    switch (element.name) {
                        case "shop_name":
                            compDetails.company = element.value;
                            break;
                        case "shop_address":
                            compDetails.address = element.value;
                            break;
                        case "shop_mobile":
                            compDetails.contact = element.value;
                            break;
                        case "shop_email":
                            compDetails.email = element.value;
                            break;
                        case "shop_gstin":
                            compDetails.gstin = element.value;
                            break;
                        case "shop_license":
                            compDetails.license_no = element.value;
                            break;


                        default:
                            break;
                    }
                });
                // return await db.company.get(company.id);
                return compDetails;
            }
        );
        return response;
    }
    async getItemDetails(itemId, customerId = 0) {
        var db = this.db.init();
        const response = await db.transaction('r', db.stockdetailshistory, async () => {
            return await db.stockdetailshistory.where("item_id").equals(itemId).toArray();
        });

        const customer_discount = await db.transaction('r', db.customer_discount,
            async () => {
                console.log(customerId);
                console.log(itemId);

                return await db.customer_discount.where({ item_id: itemId, customer_id: customerId }).first();
            });
        console.log(customer_discount);
        var batch = [];
        response.map(d => batch.push({ batch: d.batch_no, qnty: d.qnty, expdate: d.expDate, mrp: d.mrp, rate: d.rate }));
        return { batchNo: batch, details: response, customer_discount: customer_discount };
    }
    sellInsert(data, db) {
        var points = 0;

        if (data.isRedeem === 1) {
            points = 0
        } else {
            points = data.rewardPoints;
        }
        db.reward_points.where('cust_id').equals(data.customerData.customer_id + "").first((rp) => {
            var rewardData = {
                cust_id: data.customerData.customer_id + "", points: points, sync: 0,
                created_at: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace("T", " "),
                updated_at: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace("T", " ")
            };
            if (rp !== undefined) {
                rewardData.id = rp.id;
                db.reward_points.put(rewardData);
            }
            else {
                db.reward_points.add(rewardData);
            }
        });
        db.customer_balance.where({ cust_id: data.customerData.customer_id + "" }).first((uc) => {
            if (uc !== undefined) {
                uc.balance = (parseFloat(data.billTotal) - parseFloat(data.inputPay)) + "";
                uc.sync = 0;
                db.customer_balance.put(uc);
            }
        });



        db.sells
            .put({
                invoice: parseInt(data.invoice),//(++invoice)+"",
                order_date: new Date()
                    .toISOString()
                    .split("T")[0],
                customer: data.customerData.customer_id,
                doctor: "",
                ptr: 0,
                taxable: data.sellTotal,
                tax: 0,
                total_amt: data.sellTotal,
                balance: 0,
                paid: data.inputPay,
                b_paid: 1,
                payment: 1,
                draft: 0,
                mode: data.mode,
                sync: 0,
                created_at: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace("T", " "),
                updated_at: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace("T", " ")
            })
            .then(d => {
                data.barcodeData.forEach(v => {
                    db.stock_barcode.where("barcode").equals(parseInt(v.barcode)).modify({ sold: 1 });
                    db.selldetails.put({
                        barcode: parseInt(v.barcode),
                        created_at: new Date()
                            .toISOString()
                            .slice(0, 19)
                            .replace("T", " "),
                        discount: v.disc,
                        expdate: v.expdate,
                        invoice: parseInt(data.invoice),//invoice+"",
                        item_desc: null,
                        item_id: v.item_id,
                        loose_qnty: 1,
                        paid: 1,
                        price: v.mrp,
                        qnty: 1,
                        r_stock: 0,
                        returned: 0,
                        ru: v.ru,
                        s_price: v.total,
                        sell_id: d + "",
                        serial_no: null,
                        sync: 0,
                        tax: 0,
                        updated_at: new Date()
                            .toISOString()
                            .slice(0, 19)
                            .replace("T", " ")
                    });
                });

            });
    }
    getPaymentMode(callback) {
        var db = this.db.init();
        db.payment_mode.where({ active: 1 }).toArray(data => {
            callback(data);
        });
    }

    getInvoiceDetails(invoice, callback) {
        var db = this.db.init();
        const invoiceData = {};
        const sellDetails = []
        db.transaction('r',
            db.sells,
            db.customer,
            db.selldetails,
            db.items_master,
            () => {

                db.sells.get({ invoice: parseInt(invoice) }).then(s => {
                    if (s !== undefined) {
                        invoiceData.invoice = s.invoice;
                        invoiceData.orderDate = s.order_date;
                        invoiceData.doctor = s.doctor;
                        invoiceData.totalAmt = s.total_amt;
                        invoiceData.paid = s.paid;
                        db.customer.get({ id: parseInt(s.customer) }).then(c => {
                            invoiceData.customerName = c.name;
                            invoiceData.customerMobile = c.mobile;

                            db.selldetails.where({ invoice: s.invoice }).forEach(sd => {
                                const itemDetails = {}
                                db.items_master.get({ id: parseInt(sd.item_id) }).then(im => {
                                    itemDetails.itemdesc = im.itemdesc;
                                    itemDetails.expiry = sd.expdate;
                                    itemDetails.rate = im.mrp;
                                    itemDetails.qty = 1;
                                    itemDetails.gst = 0;
                                    itemDetails.barcode = sd.barcode;
                                    itemDetails.disc = parseInt(sd.price) - parseInt(sd.s_price);
                                    itemDetails.total = sd.s_price;
                                    sellDetails.push(itemDetails);
                                });
                            });
                        });
                    }
                })
            }).then(() => {
                invoiceData.sellDetails = sellDetails;
                callback(invoiceData);
            });

    }
    getUnsyncCount(callback) {
        var db = this.db.init();
        db.sellPost.where({ sync: 0 }).count(c => {

            // console.log("UnsyncCount => ", c)
            callback(c)
        });
    }


    async getDailySellsRegular() {
        var db = this.db.init();
        return await db.transaction('r', db.sell_regular, db.customer, async () => {
            let data = [];
            await db.sell_regular.where({ order_date: today() }).each(async d => {

                await db.customer.get(d.customer_id).then(c => {
                    d.customer_name = c.name
                    d.customer_contact = c.mobile
                    data.push(d);

                })
            });
            return data;
            // where('order_date').equals(today()).

        })
    }
    getSetting(cond, callback) {
        var db = this.db.init();
        if (cond.length > 1) {
            db.settings.where('name').anyOf(cond).toArray(d => {
                callback(d)
            })
        } else {
            db.settings.where({ name: cond[0] }).toArray(d => {
                callback(d)
            })
        }

    }
    async settings(value) {
        var db = this.db.init();

        return await db.settings.where({ name: value }).first();

    }

}

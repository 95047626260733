import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SellModel from "../Services/Model/SellModel";
import { sellDB } from "../DB/sell";




export const getInvoiceDetailsThunk = createAsyncThunk(
    "addSell/GetInvoiceDetails", async (invoice, api) => {

        console.log("invoice", invoice)
        try {
            var sellmodel = new SellModel();
            const currentInvoice = await sellmodel.getCurrentInvoiceFormat();
            const selldetails = await sellmodel.getInvoiceDetailsRegular(invoice)
            const companyDetails = await sellmodel.getCompanyDetails();
            var settings = {};
            if (Object.keys(selldetails).length === 0) {
                throw Error("Invalid Sell Details")
            }
            if (currentInvoice !== undefined) {

                settings = { invoice_format: currentInvoice.value };
            }
            console.log("SellDetails", selldetails);
            return { sellDetails: selldetails, companyDetails: companyDetails, settings: settings };

        } catch (err) {
            console.log(err)
        }


    }
);
export const getInvoiceDetailsCouchThunk = createAsyncThunk(
    "addSell/GetInvoiceDetailsCouch", async (invoice, api) => {

        console.log("invoice", invoice)
        try {
            // var sellmodel = new SellModel();
            // const currentInvoice = await sellmodel.getCurrentInvoiceFormat();
            // const selldetails = await sellmodel.getInvoiceDetailsRegular(invoice)
            // const companyDetails = await sellmodel.getCompanyDetails();
            const companyDetails = {
                company: localStorage.getItem("shop_name"),
                address: localStorage.getItem("shop_address"),
                gstin: localStorage.getItem("shop_gstin"),
                email: localStorage.getItem("shop_email"),
                contact: localStorage.getItem("shop_mobile"),
            };
            // var settings = {};
            // if (Object.keys(selldetails).length === 0) {
            //     throw Error("Invalid Sell Details")
            // }
            // if (currentInvoice !== undefined) {

            //     settings = { invoice_format: currentInvoice.value };
            // }
            // console.log("SellDetails", selldetails);
            // return { sellDetails: selldetails, companyDetails: companyDetails, settings: settings };

            let sellDetails = await sellDB.find({
                selector: {
                    invoice: invoice
                }
            })
            return { sellDetails: sellDetails.docs[0], companyDetails: companyDetails, settings: {} };
        } catch (err) {
            console.log(err)
        }


    }
);
export const getInvoiceSetting = createAsyncThunk(
    "printReducer/GetInvoiceSetting", async (invoice, api) => {
        var sellModel = new SellModel();
        const settings = sellModel.getInvoiceSetting();
        return settings;
    }
)
export const getCurrentInvoiceFormat = createAsyncThunk(
    "printReducer/GetCurrentInvoice", async (api) => {
        var sellModel = new SellModel();
        const currentInvoice = await sellModel.getCurrentInvoiceFormat();
        console.log("Current Invoice", currentInvoice);
        return currentInvoice;
    }
)
export const PrintReducer = createSlice({
    name: 'addSellPrint',
    initialState: {
        loading: false,
        show_print_dialog: true,
        current_invoice_format: "a4_gst_logo",
        invoice_details: null,

    },
    reducers: {
        togglePrintDialog: (state, action) => {
            state.show_print_dialog = !state.show_print_dialog
        },
        getInvoiceDetails: (state, action) => {

        },
        changeInvoice: (state, action) => {
            state.current_invoice_format = action.payload;
        }
    },
    extraReducers: {
        [getInvoiceDetailsCouchThunk.fulfilled]: (state, action) => {
            console.log("getInvoiceDetailsCouchThunk ", action.payload)
            if (action.payload !== undefined) {

                // state.current_invoice_format = action.payload.settings.invoice_format;
                state.invoice_details = action.payload;
            }
            // console.log("SellDetails", action.payload)
        },


    }

})

export const { togglePrintDialog, changeInvoice } = PrintReducer.actions

export default PrintReducer.reducer
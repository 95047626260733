import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";

export const getWhatsappConfigThunk = createAsyncThunk('whatsapp/getConfig', async (thunkApi) => {
    // axios.get('')

})
export const WhatsappReducer = createSlice({
    name: 'whatsapp',
    initialState: {
        qrtoken: null,
        status: "",
        ws: null
    },
    reducers: {
        connect: (state, action) => {
            let wsc = new WebSocket('ws://localhost:4000');
            state.ws = wsc;
            // setWs(wsc);

            state.ws.onopen = () => {
                state.status = "connected"
                // ws.send('something');
            };
            state.ws.onclose = e => {
                state.status = "closed"
            };
            state.ws.onerror = err => {
                state.ws.close();
                state.status = "closed"
            };
            state.ws.onmessage = evt => {
                const data = JSON.parse(evt.data)
                console.log(data)
                switch (data.type) {
                    case "token":
                        // setQrtoken(data.data)
                        state.qrtoken = data.data;
                        break;

                    default:
                        break;
                }
            }
        },
        disconnect: (state, action) => {
            state.ws.close()
        }

    },
    extraReducers: {
        [getWhatsappConfigThunk.fulfilled]: (state, action) => {

        }
    }
})
export const { connect, disconnect } = WhatsappReducer.actions
export default WhatsappReducer.reducer;
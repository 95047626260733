import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    history: []
}

export const loadOnlineOrderHistory = createAsyncThunk("onlineOrder/loadOnlineOrderHistory", async (data, api) => {
    var response = await axios.get("/api/orders/LoadOnlineOrderHistory");
    return response
})
export const OnlineOrdersReducers = createSlice({
    name: "onlineOrders",
    initialState: initialState,
    reducers: {

    },
    extraReducers: {
        [loadOnlineOrderHistory.fulfilled]: (state, action) => {
            let response = action.payload;
            console.log("history => ", response);
            if (response.status === 200) {
                state.history = response.data;
            }
        }

    }
})

export default OnlineOrdersReducers.reducer;
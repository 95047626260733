import React from 'react'

export default function SearchComponent({ searchField }) {
    return (
        <div className="flex-1">
            <label>

                <svg className="my-3 mx-4 absolute h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
                <input type="text" ref={searchField}
                    name="search"
                    className="p-2 pl-10 w-full rounded-full  outline-none px-2
                            font-bold text-base  focus:shadow  focus:bg-white focus:ring-1 focus:ring-gray-200
                          text-gray-500 bg-gray-100"
                    id="masterSearch" placeholder="Search medicine, customer, dealers ..." autoComplete='off' />
            </label>

        </div>
    )
}

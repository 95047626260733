import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { syncProcess } from "../sw-utility-new"
// import AddSellReducer, { getDailySellsThunk } from "./AddSellReducer";

const initState = {
    sync_running: false
}



export const syncInit = createAsyncThunk('sync/start', async (state, thunkApi) => {
    console.log("state-> ", state)
    if (state === undefined) {
        state = false
    }
    return await syncProcess(state, () => { });
})
export const SyncReducer = createSlice({
    name: "sync",
    initialState: initState,
    reducers: {

    },
    extraReducers: {
        [syncInit.pending]: (state, action) => {
            state.sync_running = true;
        },
        [syncInit.fulfilled]: (state, action) => {
            state.sync_running = false;

        }

    }

})

export default SyncReducer.reducer
import DB from './Services/Model/DB'
// import Http from './Services/Http'
import axios from 'axios';
// import { timestamp } from './Services/utility';

async function syncLocalDB(response, db, callback) {

    await db.sellRegularPost.where({ sync: 0 }).modify({ sync: 1 });
    // if (response.sells !== null)
    //     for (var i = 0; i < response.sells.length; i++) {
    //         let sell = response.sells[i];
    //         //         // let selldetails = response.sell_details[i];
    //         //         // db.selldetails.where({barcode:}) 
    //         // if (sell.local_timestamp != "") {
    //         db.sells.where({ invoice: sell.invoice }).delete();
    //         db.sells.put(sell);
    //         db.sellPost.where({ timestamp: sell.invoice + "" }).modify({ sync: 1 });
    //         // db.sells.where({invoice:sell.local_timestamp}).modify({invoice: sell.invoice,sync:1});
    //         // db.selldetails.where({invoice:sell.local_timestamp}).modify({invoice: sell.invoice,sync:1});
    //         // } else {
    //         //     // db.sells.put(sell);
    //         // }
    //         db.syncMemory.add({ timestamp: Date.now(), src: 'sells', sync_id: sell.id });
    //     }

    // if (response.selldetails !== null)
    //     for (var i = 0; i < response.selldetails.length; i++) {
    //         var sell_details = response.selldetails[i];
    //         // if (sell_details.local_timestamp != "") {
    //         // db.selldetails.where({ sell_id: sell_details.sell_id }).delete();
    //         db.selldetails.put(sell_details);
    //         // } else {
    //         //     db.selldetails.put(sell_details);
    //         // }
    //         db.syncMemory.add({ timestamp: Date.now(), src: 'selldetails', sync_id: sell_details.id });

    //     }

    if (response.items_master != null)
        // for (var i = 0; i < response.items_master.length; i++) {
        //     var items_master = response.items_master[i];
        await db.items_master.bulkPut(response.items_master);
    // db.stock_barcode.where({barcode:stock_barcode.barcode}).first((data)=>{
    //     db.stock_barcode.put({})
    // })
    // db.syncMemory.add({ timestamp: Date.now(), src: 'items_master', sync_id: items_master.id });

    // }
    if (response.sell_regular != null)
        for (var i = 0; i < response.sell_regular.length; i++) {
            var sell_regular = response.sell_regular[i];
            await db.sell_regular.where({ sync: 0 }).delete();
            await db.sell_regular.put(sell_regular);
            // db.stock_barcode.where({barcode:stock_barcode.barcode}).first((data)=>{
            //     db.stock_barcode.put({})
            // })
            // db.syncMemory.add({ timestamp: Date.now(), src: 'items_master', sync_id: items_master.id });

        }
    if (response.sell_regular_details != null)
        for (i = 0; i < response.sell_regular_details.length; i++) {
            var sell_regular_details = response.sell_regular_details[i];
            await db.sell_regular_details.where({ sync: 0 }).delete();
            await db.sell_regular_details.put(sell_regular_details);
            // db.stock_barcode.where({barcode:stock_barcode.barcode}).first((data)=>{
            //     db.stock_barcode.put({})
            // })
            // db.syncMemory.add({ timestamp: Date.now(), src: 'items_master', sync_id: items_master.id });

        }
    //     // for (var i = 0; i < response.stock_barcode.length; i++) {
    //     //     var stock_barcode = response.stock_barcode[i];
    //     //     db.stock_barcode.put(stock_barcode);
    //     //     // db.stock_barcode.where({barcode:stock_barcode.barcode}).first((data)=>{
    //     //     //     db.stock_barcode.put({})
    //     //     // })
    //     //     db.syncMemory.add({ timestamp: Date.now(), src: 'stock_barcode', sync_id: stock_barcode.id });

    //     // }
    if (response.customer_balance != null)
        for (i = 0; i < response.customer_balance.length; i++) {
            var customer_balance = response.customer_balance[i];
            await db.customer_balance.where({ cust_id:  customer_balance.cust_id}).delete();
            await db.customer_balance.put(customer_balance);
            // db.syncMemory.add({ timestamp: Date.now(), src: 'customer_balance', sync_id: customer_balance.id });

        }
    if (response.stockdetailshistory != null) {

        for (i = 0; i < response.stockdetailshistory.length; i++) {
            var stockdetailshistory = response.stockdetailshistory[i];
            await db.stockdetailshistory.put(stockdetailshistory);
            // db.syncMemory.add({ timestamp: Date.now(), src: 'stockdetailshistory', sync_id: stockdetailshistory.id });

        }
    }
    if (response.invoice_templates != null) {

        for (i = 0; i < response.invoice_templates.length; i++) {
            stockdetailshistory = response.invoice_templates[i];
            await db.invoice_templates.put(stockdetailshistory);
            // db.syncMemory.add({ timestamp: Date.now(), src: 'stockdetailshistory', sync_id: stockdetailshistory.id });

        }
    }


    // if (response.sync_memory != null) {
    //     for (var i = 0; i < response.sync_memory.length; i++) {
    //         if (response.sync_memory[i].src == "sell_regular") {
    //             console.log(response.sync_memory[i].timestamp);
    //             db.sellRegularPost.where({ timestamp: response.sync_memory[i].timestamp + "" }).modify({ sync: 1 })
    //             db.syncMemory.add({ timestamp: response.sync_memory[i].timestamp + "", src: 'sell_regular', sync_id: response.sync_memory[i].sync_id });
    //         }
    //     }
    // }

    if (response.customers != null)
        for (i = 0; i < response.customers.length; i++) {
            var customers = response.customers[i];
            await db.customer.where({ sync: 0 }).delete();
            await db.customer.put(customers);
            // db.syncMemory.add({ timestamp: Date.now(), src: 'customer', sync_id: customers.id });

        }
    //     // for (var i = 0; i < response.reward_settings.length; i++) {
    //     //     var reward_settings = response.reward_settings[i];
    //     //     db.reward_settings.put(reward_settings);
    //     //     db.syncMemory.add({ timestamp: Date.now(), src: 'reward_settings', sync_id: reward_settings.id });

    //     // }
    if (response.settings != null)
        for (i = 0; i < response.settings.length; i++) {
            var settings = response.settings[i];
            await db.settings.put(settings);
            // db.syncMemory.add({ timestamp: Date.now(), src: 'settings', sync_id: settings.id });
        }
    //     // for (var i = 0; i < response.common_barcode.length; i++) {
    //     //     var common_barcode = response.common_barcode[i];
    //     //     db.common_barcode.put(common_barcode);
    //     //     db.syncMemory.add({ timestamp: Date.now(), src: 'common_barcode', sync_id: common_barcode.id });
    //     // }
    //     // console.log(response.sells);
    callback("success");


}
// export async function syncStarter(source) {
//     // alert("SyncEvent Fired.")
//     return new Promise(function (resolve, reject) {
//         syncProcess(source, (d) => {
//             console.log("sync response", d)
//             if (d == "error") {
//                 reject(new Error("Sync Failed"));
//             } else if (d == "success")
//                 resolve("Sync successfull.")
//         });
//     });
//     // .then(d => {
//     //     console.log(d)
//     // }).catch(err => {
//     //     // alert(err)
//     //     console.log(err)
//     // })
// }
export async function syncProcess(source, callback) {
    var db = new DB();
    // const http = new Http();
    var syncData = {};
    var syncRegular = [];
    // var syncMemory = ;
    db = await db.get();


    var d = await db.customer.orderBy('timestamp').last()
    syncData.customer = d?.timestamp || 0;
    d = await db.customer_balance.orderBy('timestamp').last()
    syncData.customer_balance = d?.timestamp || 0;
    d = await db.sell_regular.orderBy('timestamp').last()
    syncData.sell_regular = d?.timestamp || 0;
    d = await db.stockdetailshistory.orderBy('timestamp').last();
    console.log(d);
    syncData.stockdetailshistory = d?.timestamp || 0;
    d = await db.company.orderBy('timestamp').last()
    syncData.company = d?.timestamp || 0;
    d = await db.settings.orderBy('timestamp').last()
    syncData.settings = d?.timestamp || 0;
    d = await db.items_master.orderBy('timestamp').last()
    syncData.items_master = d?.timestamp || 0;
    d = await db.invoice_templates.orderBy('timestamp').last()
    syncData.invoice_templates = d?.timestamp || 0;


    console.log("Customer timestamp => ", syncData)
    // var d = await db.sellPost.where({ sync: 0 }).toArray();
    // var sync_memory = await db.syncMemory.toArray();
    // console.log("syncMemory",sync_memory);
    // .then(async d => {

    //     // for (var i = 0; i < response.completedSync.length; i++) {
    //     //     db.sellPost.where({timestamp: response.completedSync[i]}).update({sync: 0});
    //     // }

    //     //console.log(response);
    //     // console.log("Syncing Data",data.data);
    // });
    // var syncData = [];
    // for (var i = 0; i < d.length; i++) {
    //     syncData.push({ data: d[i].data, timestamp: d[i].timestamp });
    // }

    var sellRegular = await db.sellRegularPost.where({ sync: 0 }).toArray();
    for (var j = 0; j < sellRegular.length; j++) {
        syncRegular.push(JSON.stringify(sellRegular[j]));
    }


    console.log("syncData", {
        "syncData": syncData,
        // "syncMemory": sync_memory, 
        "sync_regular": syncRegular
    });

    let response = await axios.post("/api/offline/SyncData?source=" + source, {
        "syncData": JSON.stringify(syncData),
        // "syncMemory": sync_memory, 
        "syncRegular": syncRegular
    });
    if (response.status === 200) {
        await syncLocalDB(response.data, db, callback)
    }

    // .then(
    //     async d => {
    //         if (d.status === 200) {
    //             console.log(d.data)
    //             await syncLocalDB(d.data, db, callback)

    //         }
    //     }
    // ).catch(err => {
    //     console.log(err)
    //     callback("error")
    // })

    // var params = {};
    // // params.body = new URLSearchParams({syncData});
    // // self.registration.showNotification("Syncing data.......");

    // params.body = JSON.stringify({ syncData: syncData, syncMemory: sync_memory });
    // // return http.Post('/api/syncData', params);
    // try {
    //     var response = await http.Post('/api/syncData', params);
    //     console.log(response);

    // } catch (e) {
    //     console.log(e)
    //     callback("error");
    //     return;
    // }




    // console.log("Sync in progress");
    //  throw new Error("Sync Failed, Network issue.");

}


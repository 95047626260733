
import bg from '../../assets/bg.svg';
import devices from '../../assets/devices.svg';
import playStore from '../../assets/PlayStore.svg';
import axios from 'axios';
import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';

import AlertBox from '../../GlobalComponent/AlertBox';
import Header from './Header';
import '../../styles/Login.css'
// import { AuthContext } from './Context/AuthContext';
export default function Login() {

    const [loginError, setLoginError] = useState("")
    const { dispatch } = useContext(AuthContext)
    function formHandler(event) {
        event.preventDefault();
        var fromData = new FormData(event.target);
        axios.post('/login', fromData).then(d => {
            if (d.status === 200) {
                if (d.data.status === "success") {
                    axios.defaults.headers.common['Authorization'] = "Bearer " + d.data.token;
                    console.log(d.data);
                    localStorage.setItem("_token", d.data.token)
                    localStorage.setItem("_userId", d.data.user.user_id)
                    localStorage.setItem("_usercode", d.data.user.usercode)
                    localStorage.setItem("permission", JSON.stringify(d.data.permission))
                    localStorage.setItem("client_user", d.data.client_code + "-" + d.data.user.user_id)
                    localStorage.setItem("_cuser", d.data.couch_user)
                    localStorage.setItem("_cpass", d.data.couch_pass)
                    localStorage.setItem("_cserver", d.data.couch_server)
                    localStorage.setItem("client_code", d.data.client_code)
                    localStorage.setItem("templates", JSON.stringify(d.data.templates))
                    localStorage.setItem("shop_name", (d.data.shop_name))
                    localStorage.setItem("shop_email", (d.data.shop_email))
                    localStorage.setItem("shop_address", (d.data.shop_address))
                    localStorage.setItem("shop_mobile", (d.data.shop_mobile))
                    localStorage.setItem("shop_gstin", (d.data.shop_gstin))
                    dispatch({
                        type: "LOGIN",
                        payload: {
                            user: d.data.user.username,
                            token: d.data.token
                        }
                    })
                    // setLoginError("")
                }

                console.log(d.data)
            } else {
                console.log("error occured")

                // console.log(d.status)
            }

        }).catch(err => {
            setLoginError("err")
        })
    }


    return (

        <div className="main-container h-screen pb-14 bg-right bg-cover"
            style={{ backgroundImage: `url(${bg})` }}
        >

            <div className="w-full container mx-auto p-6">
                <Header />


                <div className="w-full flex items-center justify-between">
                    {/* <a href="/" className="flex items-center text-indigo-400 no-underline hover:no-underline font-bold text-2xl lg:text-4xl" >
                        <svg className="h-8 fill-current text-indigo-600 pr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm-5.6-4.29a9.95 9.95 0 0 1 11.2 0 8 8 0 1 0-11.2 0zm6.12-7.64l3.02-3.02 1.41 1.41-3.02 3.02a2 2 0 1 1-1.41-1.41z" /></svg>
                        Medspace
                    </a> */}

                    <div className="flex w-1/2 justify-end content-center">
                        {/* <a className="inline-block text-blue-300 no-underline hover:text-indigo-800 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4" data-tippy-content="@twitter_handle" href="https://twitter.com/intent/tweet?url=#">
                            <svg className="fill-current h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M30.063 7.313c-.813 1.125-1.75 2.125-2.875 2.938v.75c0 1.563-.188 3.125-.688 4.625a15.088 15.088 0 0 1-2.063 4.438c-.875 1.438-2 2.688-3.25 3.813a15.015 15.015 0 0 1-4.625 2.563c-1.813.688-3.75 1-5.75 1-3.25 0-6.188-.875-8.875-2.625.438.063.875.125 1.375.125 2.688 0 5.063-.875 7.188-2.5-1.25 0-2.375-.375-3.375-1.125s-1.688-1.688-2.063-2.875c.438.063.813.125 1.125.125.5 0 1-.063 1.5-.25-1.313-.25-2.438-.938-3.313-1.938a5.673 5.673 0 0 1-1.313-3.688v-.063c.813.438 1.688.688 2.625.688a5.228 5.228 0 0 1-1.875-2c-.5-.875-.688-1.813-.688-2.75 0-1.063.25-2.063.75-2.938 1.438 1.75 3.188 3.188 5.25 4.25s4.313 1.688 6.688 1.813a5.579 5.579 0 0 1 1.5-5.438c1.125-1.125 2.5-1.688 4.125-1.688s3.063.625 4.188 1.813a11.48 11.48 0 0 0 3.688-1.375c-.438 1.375-1.313 2.438-2.563 3.188 1.125-.125 2.188-.438 3.313-.875z"></path></svg>
                        </a>
                        <a className="inline-block text-blue-300 no-underline hover:text-indigo-800 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 " data-tippy-content="#facebook_id" href="https://www.facebook.com/sharer/sharer.php?u=#">
                            <svg className="fill-current h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M19 6h5V0h-5c-3.86 0-7 3.14-7 7v3H8v6h4v16h6V16h5l1-6h-6V7c0-.542.458-1 1-1z"></path></svg>
                        </a> */}
                    </div>

                </div>

            </div>


            <div className="container pt-12  px-6 mx-auto flex flex-wrap flex-col md:flex-row items-center">


                <div className="w-full grid grid-cols-2 gap-10">

                    <div className=" w-full  justify-center lg:items-start overflow-y-hidden">
                        <h1 className="my-4 text-2xl md:text-5xl text-purple-800 font-bold leading-tight text-center md:text-left slide-in-bottom-h1">Upgrade your store with us.</h1>
                        <p className="leading-normal font-bold text-base md:text-2xl mb-8 text-center md:text-left slide-in-bottom-subtitle">Increase your sell by using latest technology with medspace</p>

                        <div className="w-full">
                            <img alt="" className=" mx-auto lg:mr-0  slide-in-bottom" src={devices} />
                        </div>
                    </div>

                    <div className="w-full py-5  overflow-hidden">
                        <div className="md:w-2/3 float-right py-10 px-5 border  md:px-10 bg-gray-50 text-gray-500 rounded-3xl shadow-xl">
                            {loginError !== "" ? <AlertBox type="error" title="Error" subtitle="Username or password is not correct" /> : ''}
                            <div className="text-center mb-10">
                                <h1 className="font-bold text-3xl text-gray-900 py-5">Login</h1>
                                <p className='font-bold'>Use your credential to login</p>
                            </div>
                            <form method="post" onSubmit={formHandler}>
                                <div>
                                    <div className="flex -mx-3">
                                        <div className="w-full px-3 mb-5">
                                            {/* <label for="" className="text-xs font-semibold px-1">First name</label> */}
                                            <div className="flex">
                                                <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-account-outline text-gray-400 text-lg"></i></div>
                                                <input type="text" name="client" className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" placeholder="Client Code" />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="flex -mx-3">
                                        <div className="w-full px-3 mb-5">
                                            {/* <label for="" className="text-xs font-semibold px-1">Email</label> */}
                                            <div className="flex">
                                                <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg"></i></div>
                                                <input type="text" name="username" className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" placeholder="Username" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex -mx-3">
                                        <div className="w-full px-3 mb-12">
                                            {/* <label for="" className="text-xs font-semibold px-1">Password</label> */}
                                            <div className="flex">
                                                <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-lock-outline text-gray-400 text-lg"></i></div>
                                                <input type="password" name="password" className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" placeholder="************" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex -mx-3">
                                        <div className="w-full px-3 mb-5">
                                            <button className="block w-full max-w-xs mx-auto bg-indigo-500 hover:bg-indigo-700 focus:bg-indigo-700 text-white rounded-lg px-3 py-3 font-semibold">Login </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

                <div className="w-full grid grid-cols-2 gap-20">




                </div>



                {/* 
                <div className="w-full pt-16 pb-6 text-sm text-center md:text-left fade-in">
                    <a href="/" className="text-gray-500 no-underline hover:no-underline" >&copy; App 2022</a>

                    <p className="text-blue-400 font-bold pb-8 lg:pb-6 text-center md:text-left fade-in">Download our app:</p>

                    <div className="flex w-full justify-center md:justify-start pb-24 lg:pb-0 fade-in">
                        <img src="assets/AppStore.svg" className="h-12 pr-4 bounce-top-icons" /> 
                        <img alt="" src={playStore} className="h-12 bounce-top-icons" />
                    </div>
                </div> */}



            </div>



        </div>


    );
}
export default function AlertBox({ type, title, subtitle }) {
    return (
        <div className={`alert flex flex-row items-center ` + (type === 'error' ? `bg-red-200  border-red-300` : `bg-green-200  border-green-300`) + ` p-2 rounded border-b-2`}>
            <div className={`alert-icon flex items-center ` + (type === 'error' ? `bg-red-100 border-red-500` : `bg-green-100 border-green-500`) + ` border-2  justify-center h-10 w-10 flex-shrink-0 rounded-full`}>
                <span className={type === 'error' ? `text-red-500` : `text-green-500`}>
                    <svg fill="currentColor"
                        viewBox="0 0 20 20"
                        class="h-6 w-6">
                        <path fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"></path>
                    </svg>
                </span>
            </div>
            <div class="alert-content ml-4">
                <div className={`alert-title font-semibold text-lg ` + (type === 'error' ? `text-red-800` : `text-green-800`)}>
                    {title}
                </div>
                <div className={`font-bold alert-description text-sm ` + (type === 'error' ? `text-red-600` : `text-green-600`)}>
                    {subtitle}
                </div>
            </div>
        </div>
    )
}
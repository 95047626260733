import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    loading: false,
    stockItems: [],
    updatedItems: [],
    deletedItems: []
}
export const getStockDetails = createAsyncThunk(
    '/stock/getStockDetails', async (data, thunkApi) => {
        return await GetBillDetails(data);
    }
)
async function GetBillDetails(data) {
    var formdata = new FormData();
    formdata.append('stock_id', data.stock_id)
    return await axios.post('/api/stock/GetBillDetails', formdata)
}
export const updateStock = createAsyncThunk('/stock/updateStock', async (data, { getState }) => {
    const state = getState();
    // console.log(state)
    return await axios.post('/api/stock/UpdateStock', { 'updatedItems': state.stockReport.updatedItems, 'deletedItems': state.stockReport.deletedItems })
})
// export const deleteStock = createAsyncThunk('/stock/deleteStock', async (data, { getState }) => {
//     return await axios.post('/api/stock/DeleteStock', { "sdh_id": data.sdh_id })
// })
const resetState = (state, action) => {
    state.deletedItems = [];
    state.updatedItems = [];
}
export const StockReducer = createSlice({
    name: 'StockReducer',
    initialState: initialState,
    reducers: {
        addUpdatedList: (state, action) => {
            // console.log(action.payload)
            // state.updatedItems.s
            var index = state.updatedItems.findIndex(obj => obj.sdh_id === action.payload.sdh_id)
            if (index !== -1) {
                state.updatedItems[index] = action.payload
            } else {
                state.updatedItems.push(action.payload)
            }
        },
        deleteStock: (state, { payload }) => {
            state.deletedItems.push(payload.sdh_id)
            var index = state.stockItems.findIndex(d => payload.sdh_id === d.id);
            state.stockItems.splice(index, 1)
        },
        reset: resetState

    },
    extraReducers: {
        [getStockDetails.fulfilled]: (state, { payload }) => {
            if (payload.status === 200) {
                resetState(state, payload)
                state.stockItems = payload.data.stock_details == null ? [] : payload.data.stock_details
            }
        },
        [updateStock.pending]: (state, { payload }) => {
            state.loading = true;
        },
        [updateStock.fulfilled]: (state, { payload }) => {
            state.loading = false;
        },
        // [deleteStock.fulfilled]: (state, { payload }) => {
        //     if (payload.status === 200) {
        //         var response = payload.data
        //         if (response.status == "SUCCESS") {
        //             var index = state.stockItems.findIndex(d => response.sdh_id == d.id);
        //             state.stockItems.splice(index, 1)
        //         }

        //     }

        // }
    }
})

export const { addUpdatedList, deleteStock } = StockReducer.actions
export default StockReducer.reducer
import PouchDB from 'pouchdb-browser';
import pouchDBFind from 'pouchdb-find';
import pouchdbQuickSearch from 'pouchdb-quick-search';
import pouchdbAuthentication from 'pouchdb-authentication';
import { CalculateCouchDBSyncPercentage } from '../Services/utility';
PouchDB.plugin(pouchdbQuickSearch);
PouchDB.plugin(pouchDBFind);
PouchDB.plugin(pouchdbAuthentication);

let COUCHDB_HOST = localStorage.getItem("_cserver");
let _cuser = localStorage.getItem("_cuser")
let _cpass = localStorage.getItem("_cpass")
let CLIENT_CODE = localStorage.getItem("client_code")
if (COUCHDB_HOST !== null && _cuser !== null && _cpass !== null) {
  for (let index = 0; index < 11; index++) {
    COUCHDB_HOST = atob(COUCHDB_HOST)
    _cuser = atob(_cuser)
    _cpass = atob(_cpass)

  }
}

let user = {
  name: _cuser,
  password: _cpass
};
console.log("COUCH-USER", user)
export const RemoteSellDatabase = new PouchDB(`${COUCHDB_HOST}/${CLIENT_CODE}-sells`, { skip_setup: true });
export const RemoteCustomerDatabase = new PouchDB(`${COUCHDB_HOST}/${CLIENT_CODE}-customers`, { skip_setup: true });
export const RemoteStockDatabase = new PouchDB(`${COUCHDB_HOST}/${CLIENT_CODE}-stocks`, { skip_setup: true });
const sellDB = new PouchDB('sells');
const customerDB = new PouchDB('customers');
const stockDB = new PouchDB('stocks');

// sellDB.createIndex({
//   index: {fields: ['invoice']} // Assuming each invoice has a 'type' and 'createdAt' field
// });


var ajaxOpts = {
  ajax: {
    headers: {
      Authorization: 'Basic ' + window.btoa(user.name + ':' + user.password)
    }
  }
};
RemoteSellDatabase.login(user.name, user.password, ajaxOpts).then(function () {
}).then(function (docs) {
  console.log(docs);
}).catch(function (error) {
  console.error(error);
});

RemoteCustomerDatabase.login(user.name, user.password, ajaxOpts).then(function () {
}).then(function (docs) {
  console.log(docs);
}).catch(function (error) {
  console.error(error);
});
RemoteStockDatabase.login(user.name, user.password, ajaxOpts).then(function () {
}).then(function (docs) {
  console.log(docs);
}).catch(function (error) {
  console.error(error);
});

let SellSync = sellDB.sync(RemoteSellDatabase, {
  live: true,
  retry: true
}).on('change', function (info) {
  console.log("syncing")
  // handle change
}).on('error', function (err) {
  // handle error
  // console.log(err);
});
let CustomerSync = customerDB.sync(RemoteCustomerDatabase, {
  live: true,
  retry: true
}).on('change', function (info) {
  let percentage = CalculateCouchDBSyncPercentage(info)
  console.log("syncing-customer => ", percentage, "%")
  // handle change
}).on('error', function (err) {
  // handle error
  console.log(err);
});

let StockSync = stockDB.sync(RemoteStockDatabase, {
  live: true,
  retry: true
}).on('change', function (info) {
  console.log("syncing-stocks")
  // handle change
}).on('error', function (err) {
  // handle error
  console.log(err);
});

// creating index for stocks for faster lookup
var designDocStocks = {
  _id: '_design/stock_itemdesc_index',
  views: {
    'stock_itemdesc_index': {
      map: "function(doc) { emit( doc.itemdesc + ' '+doc.batch_no+' '+doc.qnty)}"
    }
  }
};

stockDB.put(designDocStocks).then(function (info) {

  console.log("Index created", info);
  return stockDB.query('stock_itemdesc_index', { stale: 'update_after' });
  // design doc created
}).catch(function (err) {
  console.log(err);
});

var designDocCustomers = {
  _id: '_design/customer_name_index',
  views: {
    'customer_name_index': {
      map: "function(doc) { emit(doc.name)}"
    }
  }
};

customerDB.put(designDocCustomers).then(function (info) {
  console.log("Customer Index created", info);
  return customerDB.query('customer_name_index', { stale: 'update_after' });
  // design doc created
}).catch(function (err) {
  console.log(err);
});



// customerDB.search({

//   fields: ['name'],
//   build: true
// }).then((data) => {
//   console.log(data)
// })

function CancelSync() {
  SellSync.cancel();
  CustomerSync.cancel();
  StockSync.cancel();

}

export {
  sellDB,
  customerDB,
  stockDB,
  CancelSync
}

// import Login from './Login';
// import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useReducer } from 'react';
import { AuthContext, initialState, reducer } from './Context/AuthContext';
import Home from './Pages/Home';
import Login from './Pages/Login';
// import { syncProcess } from './sw-utility';

function App() {



  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <div className="App">
      <AuthContext.Provider value={{ state, dispatch }}  >
        {state.isAuthenticated ? <Home /> : <Login />}
      </AuthContext.Provider>
    </div>
  );
}

export default App;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
    accounts: [],
    isLoading: false
}
export const getAllAccountThunk = createAsyncThunk('admin/getAllAccount', async (thunkApi) => {
    var response = await axios.get("/api/admin/GetAllAccount")
    if (response.status === 200) {
        return response.data;
    }
    return [];
})
const AccountSlice = createSlice({
    name: "AdminReducer",
    initialState,
    reducers: {

    },
    extraReducers: {
        [getAllAccountThunk.fulfilled]: (state, action) => {
            state.accounts = action.payload;
        }
    }
})

export default AccountSlice.reducer;
import React, { useEffect } from 'react'
import SyncStatus from './SyncStatus'
import Mousetrap from 'mousetrap'
// import './css/HeaderMenu.css'
import { Link, NavLink } from 'react-router-dom'
import { DBIcon, DrawIcon, GlobeIcon, GstIcon, LabIcon, NotePadIcon, PieChartIcon, RefreshIcon, RupeeRoundIcon, SellIcon, SettingsIcon, ShoppingCart, WhatsappIcon } from '../Icons/Icons'
import { useSelector } from 'react-redux'
// import SyncLoading from '../components/SellPage/component/SyncLoading'
// import Loader from 'react-loader-spinner'
export default function HeaderMenu() {

    const sync_running = useSelector((state) => state.sync.sync_running);
    const permissions = JSON.parse(localStorage.getItem("permission"))
    useEffect(() => {
        Mousetrap.bind('f1', (e) => {
            e.preventDefault();
            window.location.reload()
        })
    })
    return (
        <div className="w-full  shadow sticky top-0  bg-white ">

            <div className="flex justify-center space-x-0 font-sans ml-4">
                {permissions && permissions.find(d => d.permission == "Sell") != undefined ?
                    <NavLink to="/sell" activeClassName="bg-green-100" >
                        <div className="p-2 px-3 hover:bg-gray-100 ">
                            <SellIcon />
                            Sell (F1)
                        </div>
                    </NavLink>
                    : null}
                {permissions && permissions.find(d => d.permission == "Stock") != undefined ?
                    <NavLink to="/importStock" activeClassName="bg-green-100" >
                        <div className="p-2 px-3 hover:bg-gray-100">
                            <DBIcon />
                            Stock (F2)
                        </div>
                    </NavLink>
                    : null}
                {permissions && permissions.find(d => d.permission == "Payments") != undefined ?
                    <NavLink to="/payment" activeClassName="bg-green-100">
                        <div className="p-2 px-3 hover:bg-gray-100">
                            <RupeeRoundIcon />
                            Payments (F3)
                        </div>
                    </NavLink>
                    : null}
                {permissions && permissions.find(d => d.permission == "Drawer") != undefined ?
                    <NavLink to="/drawer" activeClassName="bg-green-100">
                        <div className="p-2 px-3 hover:bg-gray-100">
                            <DrawIcon />
                            Drawer (F4)
                        </div>
                    </NavLink>
                    : null}
                {permissions && permissions.find(d => d.permission == "Expiry") != undefined ?
                    <NavLink to="/expiry" activeClassName="bg-green-100">
                        <div className="p-2 px-3 hover:bg-gray-100">
                            <LabIcon />
                            Expiry (F6)
                        </div>
                    </NavLink>
                    : null}
                {permissions && permissions.find(d => d.permission == "Master") != undefined ?
                    <NavLink to="/masters" activeClassName="bg-green-100" >
                        <div className="p-2 px-3 hover:bg-gray-100">
                            <NotePadIcon />
                            Master
                        </div>
                    </NavLink>
                    : null}


                {/* <div className="p-2 px-3 hover:bg-gray-100"><a href="/reportsSell" >
                    <svg className="h-8 m-auto text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    Daily Sells</a>
                </div> */}

                {/* <div className="p-2 px-3 hover:bg-gray-100"><NavLink to="/stock-report" className=" ">
                    <svg className="h-8 m-auto text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                    </svg>
                    Stock Reports</NavLink>
                </div> */}
                {permissions && permissions.find(d => d.permission == "GST") != undefined ?
                    <NavLink to="/gst" activeClassName="bg-green-100">
                        <div className="p-2 px-3 hover:bg-gray-100">
                            <GstIcon />
                            GST
                        </div>
                    </NavLink>
                    : null}
                {permissions && permissions.find(d => d.permission == "Settings") != undefined ?
                    <NavLink to="/settings" activeClassName="bg-green-100">
                        <div className="p-2 px-3 hover:bg-gray-100">
                            <SettingsIcon />
                            Settings
                        </div>
                    </NavLink>
                    : null}
                {permissions && permissions.find(d => d.permission == "Dashboard") != undefined ?
                    <NavLink to="/dashboard" activeClassName="bg-green-100">
                        <div className="p-2 px-3 hover:bg-gray-100">
                            <PieChartIcon />
                            Dashboard
                        </div>
                    </NavLink>
                    : null}
                {permissions && permissions.find(d => d.permission == "Whatsapp") != undefined ?
                    <NavLink to="/whatsapp" activeClassName="bg-green-100">
                        <div className="p-2 px-3 hover:bg-gray-100">
                            <WhatsappIcon />
                            Whatsapp
                        </div>
                    </NavLink>
                    : null}
                {permissions && permissions.find(d => d.permission == "OnlineOrders") != undefined ?
                    <NavLink to="/orders" activeClassName="bg-green-100">
                        <div className="p-2 px-3 hover:bg-gray-100">
                            <ShoppingCart />
                            OnlineOrders
                        </div>
                    </NavLink>
                    : null}
                {permissions && permissions.find(d => d.permission == "Admin") != undefined ?
                    <NavLink to="/admin" activeClassName="bg-green-100">
                        <div className="p-2 px-3 hover:bg-gray-100">
                            <ShoppingCart />
                            Admin
                        </div>
                    </NavLink> : null}
                <div className="p-2 px-3 hover:bg-gray-100">
                    <SyncStatus />
                </div>

                <div className="absolute right-0 p-5 " id="syncSpinnerPortal">
                    {sync_running ? <RefreshIcon className="animate-spin-slow " /> : null}
                </div>

                {/* <div className="row">

                        <div className="col-sm-12" style={{width:'90%'}}>

                            <br />

                            <a href="/demoExcel" target="_blank"> Download Demo Excels</a>

                        </div>


                    </div> */}
                {/* <div style={{float:'right', paddingLeft:'10px'}}>
                    <Loader type="ThreeDots"  color="#6f42c1" height={60} width={40}  />
                    </div> */}


                {/* <SyncLoading /> */}
            </div>


        </div>
    )
}
import DB from "./DB";
// import { param } from "jquery";
import Http from "../../Services/Http";
// import axios from "axios";
// import { timestamp, today } from "../utility";
export default class SettingsModel {
    constructor() {
        // super();
        this.http = new Http();
        this.db = new DB();
    }

    async setSettings(settings) {
        var db = this.db.init();
        for (const key in settings) {
            // settingsArr.push({ name: key, value: action[key] })
            await db.settings.where({ name: key }).modify(function (value) {
                value.value = settings[key];
            });
        }

        console.log(settings);
    }

}
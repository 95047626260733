import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Axios from 'axios'
import db from './DB/sell';
// import reportWebVitals from './reportWebVitals';
// import { syncProcess, syncStarter } from './sw-utility-new'

import { Provider } from 'react-redux'
import store from './store'
// import ErrorBoundary from './Services/ErrorBoundary';
import { DEV_API_URL, ENV, LIVE_API_URL } from './global';

Axios.defaults.baseURL = ENV === "local" ? DEV_API_URL : LIVE_API_URL;
// Axios.defaults.baseURL = "http://localhost:9900"
Axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("_token");

// document.addEventListener("syncData", syncStarter(), false);

ReactDOM.render(
  // <React>
  <Provider store={store}>
    {/* <ErrorBoundary> */}
    <App />
    {/* </ErrorBoundary> */}
  </Provider>,
  // </React>,
  document.getElementById('root')
);


// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', function () {
//     navigator.serviceWorker.register('worker.js').then(function (registration) {
//       console.log('Worker registration successful', registration.scope);
//     }, function (err) {
//       console.log('Worker registration failed', err);
//     }).catch(function (err) {
//       console.log(err);
//     });
//   });
// } else {
//   console.log('Service Worker is not supported by browser.');
// }






// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


export const addCustomer = createAsyncThunk("master/AddCustomer", async (data, thunkApi) => {
    var response
    try {
        console.log(data.url)
        response = await axios.post(data.url, data.data)
        if (response.status === 200) {
            console.log(response.data)
            return { 'status': response.data.status === undefined ? 'FAILED' : response.data.status, 'error': response.data.Message ?? response.data.error };
        }
        return { 'status': 'FAILED', 'error': '' };
    } catch (err) {
        console.log(err)
        return { 'status': 'FAILED', 'error': err.message };
    }
})

export const getAllCustomer = createAsyncThunk("master/GetAllCustomer", async (data, thunkApi) => {
    var response = await axios.get('/api/master/GetAllCustomer');
    console.log(response);
})

const initialState = {
    status: "",
    error: ""

}
export const MasterReducer = createSlice(
    {
        name: "MasterReducer",
        initialState: initialState,
        reducers: {
            resetStatus: (state, { payload }) => {
                state.status = '';
                state.error = '';
            }
        },
        extraReducers: {
            // [addCustomer.rejected]: (state, action) => {
            //     console.log(action.payload)
            // },
            [addCustomer.fulfilled]: (state, action) => {

                var response = action.payload;
                console.log("Response =>", response)

                state.status = response.status;
                state.error = response.error;


            },

        }
    }
)
export const { resetStatus } = MasterReducer.actions
export default MasterReducer.reducer
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
// import SellModel from "../Services/Model/SellModel";
import SettingsModel from "../Services/Model/SettingsModel";


export const saveSetting = createAsyncThunk("settings/SaveSettings", async (action, api) => {
    console.log(action);
    // const sellModel = new SellModel();
    // sellModel.saveSetting(action.payload);

})
const initialState = {
    systemSettingSaved: false,
    systemSetting: {},
    printerSetting: {},
    invoiceFormats: [],
    accessControl: {}
}
export const getSystemSetting = createAsyncThunk("settings/getSystemSettings", async (action, api) => {
    var response = await axios.get("/api/settings/getSystemSettings");
    // console.log(response)
    return response.data
})
export const setSystemSettings = createAsyncThunk("settings/setSystemSettings", async (action, api) => {
    var settingsModel = new SettingsModel();

    // var settingsArr = [];

    // console.log("Arr ", settingsArr)
    await settingsModel.setSettings(action);
    var response = await axios.post("api/settings/setSystemSettings", action);
    return response.data;
})
export const SettingsReducer = createSlice(
    {
        name: "settings",
        initialState: initialState,
        reducers: {

        },
        extraReducers: {
            [saveSetting.fulfilled]: (state, action) => {


            },
            [getSystemSetting.fulfilled]: (state, action) => {
                var obj = {};
                if (action.payload.invoices.constructor === Array) {
                    state.invoiceFormats = action.payload.invoices;
                }
                if (action.payload.settings.constructor === Array) {
                    action.payload.settings.forEach(element => {
                        obj[element.name] = element.value;
                    });
                    state.systemSetting = obj;
                }


                // console.log("Fulfilled ", obj);
            },
            [setSystemSettings.fulfilled]: (state, action) => {
                Swal.fire({
                    // position: 'top-end',
                    icon: 'success',
                    title: 'Settings saved successfully.',
                    showConfirmButton: false,
                    timer: 1500
                })
                // state.systemSettingSaved = true;
            }

        }

    }
)
export default SettingsReducer.reducer
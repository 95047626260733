export const syncTables = [
    "company",
    "items_master",
    // "stock_barcode",
    "stockdetailshistory",
    "customer",
    "customer_discount",
    // "sells",
    // "selldetails",
    "sell_regular",
    "sell_regular_details",
    // // "unpaidCustomers",
    // "item_merge",
    // "common_barcode",
    "drawermaster",
    // "redeem_history",
    // "reward_points",
    // "reward_settings",
    "customer_balance",
    "payment_mode",
    "catmaster",
    "settings",
    "invoice_templates",
    "invoices"
];
// db.js
import Dexie from 'dexie';

export const db = new Dexie('medspace');
db.version(1).stores({
    company: `++id,contact,gstin,email,timestamp`,
    items_master: `++id,itemdesc,timestamp`,
    stockdetailshistory: `++id,item_id,batchno,[item_id+batch_no],barcode,timestamp`,
    stock_barcode: `++id,barcode,item_id,[item_id+sold],[barcode+sold],[c_barcode+sold],sdh_id,c_barcode,sold,timestamp`,
    item_merge: `++id,item_id,mapped_id,timestamp`,
    common_barcode: `++id,item_id,barcode,timestamp`,
    syncMemory: `++id,src,sync_id,timestamp`,
    drawermaster: `++id,drawer,timestamp`,
    selldetails: '++id,item_id,barcode,sell_id,invoice,created_at,timestamp,local_timestamp',
    sells: '++id,invoice,order_date,customer,created_at,timestamp,local_timestamp',
    sellRegularPost: '++id,invoice,order_date,customer,bill_total,timestamp,sync',
    sell_regular: '++id,invoice,order_date,customer_id,timestamp,sync',
    sell_regular_details: '++id,sell_id,item_id,batch,timestamp,sync',
    customer: '++id,name,mobile,timestamp,sync',
    customer_balance: '++id,cust_id,balance,wallet_amt,timestamp',
    customer_discount: '++id,customer_id,item_id,[item_id+customer_id],timestamp',
    sellPost: '++id,sync,timestamp',
    reward_points: '++id,cust_id,points,timestamp',
    redeem_history: '++id,customer_id,invoice,points,timestamp',
    reward_settings: '++id,category,percentage,timestamp',
    payment_mode: '++id,name,active,timestamp',
    settings: '++id,name,value,timestamp',
    catmaster: '++id,category,timestamp',
    invoice_templates: '++id,[template_id+section],template_id,invoice_name,invoice_code,section,timestamp',
    invoices: '++id,name,value,timestamp'

});
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
export default function OfflineDetector({ children }) {
    const [isOnline, setIsOnline] = useState(true);
    useEffect(() => {
        window.addEventListener('online', () => {
            setIsOnline(true);
        });
        window.addEventListener('offline', () => {
            setIsOnline(false);
        });
    }, []);
    return (
        <>
            {!isOnline && <Helmet><meta name="theme-color" content="#000000" /></Helmet>}
            {!isOnline && (
                <div
                    style={{
                        backgroundColor: 'red',
                        color: 'white',
                        textAlign: 'center',
                        padding: '10px',
                    }}
                >
                    You are offline
                </div>
            )}
            {children}
        </>
    )
}
export default class Http {
    constructor(url = "") {
        // this.domain = "http://localhost:8000"
        this.domain = "https://api.medspace.in"
        if (url !== "")
            this.domain = url
        else {
            if (typeof window === 'undefined') {
                // this.domain = self.location.origin;
            } else {
                this.domain = window.location.origin;
            }
        }

        // console.log()
    }
    async Get(url, params = {}) {
        params.method = 'GET';

        const response = await fetch(this.domain + url, params);
        return await response.json();
    }
    async Post(url, params, type) {
        params.method = 'POST';
        // params.credentials ='same-origin'
        if (type === 'json') {
            params.headers = {
                'Content-Type': 'application/json',
                'Accept': '*/*'
                //  'Content-Type': 'application/x-www-form-urlencoded',
            }
        }
        // console.log(this.domain)
        const response = await fetch(this.domain + url, params);
        return await response.json();
    }

    async PostS(url, params) {
        var token = localStorage.getItem("_token")
        params.method = "POST"
        params.headers = {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
        }
        const response = await fetch(this.domain + url, params);
        return await response.json();
        // body: {id:"1"}, // whatever data you want to send

    }
    async GetS(url, params) {
        var token = localStorage.getItem("_token")
        params.method = "GET"
        params.headers = {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
        }
        const response = await fetch(this.domain + url, params);
        return await response.json();
        // body: {id:"1"}, // whatever data you want to send

    }


}